/**
 * Created by osirvent on 07/06/2016.
 */
angular
    .module('annexaApp')
    .controller('DiligenceStampTypeController',['$scope', '$rootScope', 'HeaderService', '$state', 'TableFilter', 'DTColumnBuilder', '$filter', '$compile', 'Language', 'CommonAdminService', 'CommonAdminModals', 'GlobalDataFactory','AnnexaFormlyFactory', 'RestService', 'DialogsFactory', 'apiAdmin', 'AnnexaModalFactory', 'CacheFactory',
        function($scope, $rootScope, HeaderService, $state, TableFilter, DTColumnBuilder, $filter, $compile, Language, CommonAdminService, CommonAdminModals, GlobalDataFactory, AnnexaFormlyFactory, RestService, DialogsFactory, apiAdmin, AnnexaModalFactory, CacheFactory) {  
            $scope.showLoadingdiv = false;
            $scope.languageColumn = Language.getActiveColumn();
            $scope.tfilter = new TableFilter();
            $scope.tfilter.addElement($scope.languageColumn, 'text', 1, 'global.literals.name');

            $scope.dtDefDiligenceStampType = new TableData('sign', 'DiligenceStampType', './api/sign/byfilter').setSort([[1, 'asc']]).setSortName('id');
            
            var nameColumn = new DatabaseTranslatedColumn($filter, 'global.literals.name', Language.getActiveColumn());
            var textColumn = new DatabaseTranslatedColumn($filter, 'global.diligence_stamp_type.text', 'diligenceTextL'+Language.getActiveColumn().substring(1));
            var diligenceStampTypeActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                    new ActionButton('global.literals.see', 'openDiligenceStampType([data])', 'fa-eye'),
                    [new ActionButton('global.literals.remove', 'deleteDiligenceStampType([data])')]);


            $scope.tableDiligenceStampType = {
            	dtColumns: [
                    DTColumnBuilder.newColumn('id').withTitle("id").withOption('width', '6%'),
                    DTColumnBuilder.newColumn(nameColumn.getColumn()).withTitle(nameColumn.getTitle()).renderWith(nameColumn.getRender),
                    DTColumnBuilder.newColumn(textColumn.getColumn()).withTitle(textColumn.getTitle()).renderWith(textColumn.getRender),
                    DTColumnBuilder.newColumn('id').withOption('className', 'text-center').withOption('width', '90px').withTitle(diligenceStampTypeActionsColumn.getTitle()).renderWith(diligenceStampTypeActionsColumn.getRender).notSortable()
                ],
                filterCall: $scope.tfilter.getFilterCall(),
                filterCallAux: {loggedUser: $rootScope.LoggedUser.id, langColumn: $scope.languageColumn},
                reloadInternalData: function (resetPaging) {
                    this.filterCall = $scope.tfilter.getFilterCall();
                    this.filterCallAux = $scope.tfilter.getFilterCallAux();
                    this.filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                    this.filterCallAux.langColumn = $scope.languageColumn;
                    this.table.reloadData(function callbackInstance(json) {}, resetPaging);
                }
            };

            HeaderService.onChangeState($scope, function (message) {
                if(message.state.name == 'annexa.admin.diligence_stamp_type') {
                    $rootScope.subHeadButtons = [
                        new HeadButtonSearch("#tableFilter"),
                        new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#tableDiligenceStampType','fa fa-plus','global.diligence_stamp_type.titleNew',undefined,'openNewDiligenceStampType')
                    ];
                }
            });

            HeaderService.changeState($state.current);

            $scope.deleteDiligenceStampType = function(id) {
                if(id) {
                	DialogsFactory.confirm('global.diligence_stamp_type.deleteType', 'global.diligence_stamp_type.deleteTypeConfirm')
                    .then(function (data) {
                    	RestService.checkDelete('./api/sign/diligence_stamp_type/check_delete/' + id)
                        .then(function(data) {
                            if(data) {
                                RestService.delete('./api/sign/diligence_stamp_type/' + id).then(function(data) {
                                	$scope.tableDiligenceStampType.reloadInternalData(true);
                                }).catch(function (error) {
                                	DialogsFactory.error($filter('translate')('global.diligence_stamp_type.deleteError'), $filter('translate')('DIALOGS_ERROR_MSG'));
                                });
                            }else{
                            	DialogsFactory.error($filter('translate')('global.diligence_stamp_type.deleteErrorDiligenceStampType'), $filter('translate')('DIALOGS_ERROR_MSG'));
                            }
                        }).catch(function(error) {
                            DialogsFactory.error($filter('translate')('global.diligence_stamp_type.deleteError'), $filter('translate')('DIALOGS_ERROR_MSG'));
                        });       	
     	           }).catch(function (data) {
     		               //Empty
     		       });
                }
            };

            $scope.openNewDiligenceStampType = function () {
                var modal = angular.copy(CommonAdminModals.diligenceStampTypeNew);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = { readOnly: false};
                modal.extra = $scope.tableDiligenceStampType;
                AnnexaFormlyFactory.showModal('modalDiligenceStampType', modal, $scope.saveNewDiligenceStampType, false, undefined);
            };

            $scope.saveNewDiligenceStampType = function(){
                if (this.annexaFormly.form.$valid) {
                    var self = this;
                    var model = this.annexaFormly.model.modal_body;
                    
                    var profiles = [];
                    _.forEach(model.profiles, function(item) {
                    	profiles.push({ profile: {id: item} });
                    });

                    var certificate = undefined;
                    if (model.certificate)
                    	certificate = $linq($rootScope.app.configuration.organ_certificates.certificates).firstOrDefault(undefined,"x => x.alias == '" + model.certificate + "'");
                    	
                    var diligenceStampType = {
                        language1: ((model.language1)?model.language1:'NotTranslate'),
                        language2: ((model.language2)?model.language2:'NotTranslate'),
                        language3: ((model.language3)?model.language3:'NotTranslate'),
                        diligenceTextLanguage1: ((model.diligenceTextLanguage1)?model.diligenceTextLanguage1:'NotTranslate'),
                        diligenceTextLanguage2: ((model.diligenceTextLanguage2)?model.diligenceTextLanguage2:'NotTranslate'),
                        diligenceTextLanguage3: ((model.diligenceTextLanguage3)?model.diligenceTextLanguage3:'NotTranslate'),
                        canChange: ((model.canChange)?model.canChanget:false),
                        pages: model.pages,
                        certificateType:model.certificateType,
                        certificate: certificate?JSON.stringify(certificate):undefined,
                        profiles:profiles
                    };

                    RestService.insert('./api/sign/diligence_stamp_type', diligenceStampType).then(function(data) {
                        self.extra.reloadInternalData(true);
                        if(GlobalDataFactory.diligenceStampTypes){
                        	GlobalDataFactory.diligenceStampTypes.length = 0;
                        }else{
                        	GlobalDataFactory.diligenceStampTypes = [];
                        }
                        if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('diligenceStampTypes')){
                        	CacheFactory.get('globalDataCache').remove('diligenceStampTypes')
                        }
                        GlobalDataFactory.loadGlobalData().then(function (data) {}).catch(function (error) {});
                        self.close();
                    }).catch(function(error) {
                    	DialogsFactory.error($filter('translate')('global.errors.unknownNew'), $filter('translate')('DIALOGS_ERROR_MSG'));
                    });
                }
            };
            
            $scope.openDiligenceStampType = function (id) {
                if(id) {
                	RestService.findOne('DiligenceStampType', id).then(function(data) {
                        var modal = angular.copy(CommonAdminModals.diligenceStampTypeEdit);
                        modal.data = JSOG.decode(data.data);
                        modal.dataAux = {};
                        modal.dataAux.profilesAux = $linq(modal.data.profiles).select("x => x.profile").toArray();
                        modal.alerts = [];
                        
                        var selectableOptions = [];
                        _.forEach(GlobalDataFactory.allProfiles, function (value, key) {
                        	var haveProfile = false;
                        	if(modal.dataAux.profilesAux) {
                        		haveProfile = modal.dataAux.profilesAux.some(function (profile) {
                        			  return profile.id === value.id;
                        			});
                        	}
                            if(value.expiryDate == null || haveProfile) {
                                selectableOptions.push(value);
                            }
                        });
                        modal.profiles = $linq(selectableOptions).orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
                        
                        modal.languageColumn = Language.getActiveColumn();
                        modal.descriptionColumn = 'description';
                        modal.isLanguage = function(lang){
                        	var languages = $rootScope.app.languagedef;
                        	var hide = false;
                        	if(languages){
                                angular.forEach(languages, function (value2, key2) {
                                    if(value2 && value2.column && value2.column == lang) {
                                        hide = true;
                                    }
                                });
                            }
                        	return hide;
                        }
                        modal.printCanChange = function (model) {
                            if(model) {
                                return $filter('translate')('global.diligence_stamp_type.canChangeYes');
                            } else {
                                return $filter('translate')('global.diligence_stamp_type.canChangeNo');
                            }
                        };
                        var langLabel = $linq($rootScope.app.languagedef).firstOrDefault({label:''},"x => x.column == 'language1'").label;
                        modal.langText1 = $filter('translate')('global.literals.description') + " " + ((langLabel)?langLabel:'');
                        langLabel = $linq($rootScope.app.languagedef).firstOrDefault({label:''},"x => x.column == 'language2'").label;
                    	modal.langText2 = $filter('translate')('global.literals.description') + " " + ((langLabel)?langLabel:'');
                    	langLabel = $linq($rootScope.app.languagedef).firstOrDefault({label:''},"x => x.column == 'language3'").label;
                		modal.langText3 = $filter('translate')('global.literals.description') + " " + ((langLabel)?langLabel:'');
                        modal.pages = angular.copy(apiAdmin.diligenceStampPages);
                        modal.certificateTypes = angular.copy(apiAdmin.diligenceStampCertificateType);
                        modal.printLangugaes  = function(model){
                        	var langs = '';
                            angular.forEach(model, function (val, key) {
                                if(langs != '') {
                                	langs += ', ';
                                }
                                langs += val[$scope.languageColumn];
                            });

                            return langs;
                        }
                        modal.printDescription  = function(model){
                        	return ((model)?$filter('translate')('global.diligence_stamp_type.'+model):'');
                        }
                        modal.certificates = angular.copy($rootScope.app.configuration.organ_certificates.certificates);
                        modal.printCertificate  = function(model){
                        	if (model && model.file)
                        		return model.file;
                        }
                        modal.fileColumn = 'file';
                        modal.extra = $scope.tableDiligenceStampType;
                        modal.updateDiligenceStampType = $scope.updateDiligenceStampType;
                        AnnexaModalFactory.showModal('modalDiligenceStampTypeEdit', modal);
                    });
            } 
        };
        
        $scope.updateDiligenceStampType = function(val, prop) {
        	var self = this;
            var diligenceStampType = angular.copy(this.data);
            self.alerts = [];
            if (prop == 'profiles') {
            	diligenceStampType[prop] = [];
                _.forEach(val, function (item) {
                	diligenceStampType[prop].push({profile: {id: item.id, language1: item.language1, language2: item.language2, language3: item.language3}});
                });
            }else if(prop == 'certificateType' || prop == 'pages'){
            	diligenceStampType[prop] = val.id;
            	if (prop == 'certificateType' && diligenceStampType[prop] != 'ORGAN')
            		diligenceStampType['certificate'] = undefined;
            } else if(prop == 'certificate') {
            	diligenceStampType[prop] = JSON.stringify({ alias: val.alias, file: val.file, pin: val.pin });
            } else if(prop != 'language1' && prop != 'language2' && prop != 'language3') {
            	diligenceStampType[prop] = val;
            }
            
            RestService.update('./api/sign/diligence_stamp_type/' + diligenceStampType.id, diligenceStampType)
                .then(function(data) {
                    self.extra.reloadInternalData(true);
                    if (self.data){
                    	self.data = data;
                        if(!self.dataAux){
                        	self.dataAux = {};
                        }
                        if (data.certificate)
                        	self.data.certificate = JSON.parse(data.certificate);
                        self.dataAux.profilesAux = $linq(self.data.profiles).select("x => x.profile").toArray()
                    }
                    if(GlobalDataFactory.diligenceStampTypes){
                    	GlobalDataFactory.diligenceStampTypes.length = 0;
                    }else{
                    	GlobalDataFactory.diligenceStampTypes = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('diligenceStampTypes')){
                    	CacheFactory.get('globalDataCache').remove('diligenceStampTypes')
                    }
                    GlobalDataFactory.loadGlobalData().then(function (data) {}).catch(function (error) {});
            }).catch(function (error) {
               	DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'), $filter('translate')('DIALOGS_ERROR_MSG'));
            });
        };
            
    }]);