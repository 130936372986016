/**
 * Created by aguerrero on 03/04/2016.
 */

angular
    .module('annexaApp')
    .controller('CouncillorAdminController', ['$q','$scope', '$state', '$filter', 'DTColumnBuilder', '$rootScope', 'TableFilter', 'HeaderService', 'Language', 'CommonAdminModals', 'AnnexaFormlyFactory', 'AdminFactory', 'RestService', 'AnnexaModalFactory', 'DialogsFactory', 'CommonService', 'ErrorFactory', 'GlobalDataFactory', 
function ($q, $scope, $state, $filter, DTColumnBuilder, $rootScope, TableFilter, HeaderService, Language, CommonAdminModals, AnnexaFormlyFactory, AdminFactory, RestService, AnnexaModalFactory, DialogsFactory, CommonService, ErrorFactory, GlobalDataFactory) {
	$rootScope.loading(false);
        $scope.councillors = [];
        $scope.langColumn = Language.getActiveColumn();

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.admin.councillors') {
                $rootScope.subHeadButtons = [new HeadButton('btn primary breadDivButton ml-sm-pt mb-sm-pt mb-md-0',undefined,'#admin-councillors','fa fa-plus','global.commonAdmin.modal.councillor.titleNew',undefined,'newCouncillor')];
            }
        });
        HeaderService.changeState($state.current);

        $scope.councillors = CommonService.getNestableData(angular.copy(AdminFactory.councillorsList), 'councillor', 'orderView');

        $scope.toggle = function (scope) {
            scope.toggle();
        };

        $scope.newCouncillor = function (node) {
			var modal = angular.copy(CommonAdminModals.councillorNew);
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};
            modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[0].templateOptions.labelProp = $scope.langColumn;
            modal.annexaFormly.fields[0].fieldGroup[0].fieldGroup[0].templateOptions.options = AdminFactory.councillorTypes;
            modal.annexaFormly.fields[0].fieldGroup[1].fieldGroup[1].templateOptions.labelProp = $scope.langColumn;
            modal.annexaFormly.fields[0].fieldGroup[1].fieldGroup[1].templateOptions.options = AdminFactory.positions;
            modal.annexaFormly.fields[0].fieldGroup[2].fieldGroup[0].templateOptions.labelProp = $scope.langColumn;
            modal.annexaFormly.fields[0].fieldGroup[2].fieldGroup[0].templateOptions.options = angular.copy(AdminFactory.councillorsList);
			if(node && node.id && node.councilMan){
				modal.annexaFormly.fields[0].fieldGroup[2].fieldGroup[0].defaultValue = node;	
			}
            modal.annexaFormly.fields[0].fieldGroup[2].fieldGroup[1].templateOptions.labelProp = $scope.langColumn;
            modal.annexaFormly.fields[0].fieldGroup[2].fieldGroup[1].templateOptions.options = angular.copy(GlobalDataFactory.presetSignCircuit);
            AnnexaFormlyFactory.showModal("modalCouncillor", modal, $scope.saveNewCouncillor, false);
        };

		$scope.saveNewCouncillor = function (councillor) {
			if(councillor && councillor.annexaFormly && councillor.annexaFormly.model && councillor.annexaFormly.model.modal_body){
				var modalBody = councillor.annexaFormly.model.modal_body;
				var model = {
					language1:((modalBody && modalBody.row1 && modalBody.row1.language1)?modalBody.row1.language1:'NotTranslated'),
					language2:((modalBody && modalBody.row1 && modalBody.row1.language2)?modalBody.row1.language2:'NotTranslated'),
					language3:((modalBody && modalBody.row1 && modalBody.row1.language3)?modalBody.row1.language3:'NotTranslated'),
					councilMan:((modalBody && modalBody.row2 && modalBody.row2.councilMan && modalBody.row2.councilMan.id)?{id:modalBody.row2.councilMan.id}:undefined),
					councilManPosition:((modalBody && modalBody.row2 && modalBody.row2.councilManPosition)?{id:modalBody.row2.councilManPosition}:undefined),
					type:((modalBody && modalBody.row1 && modalBody.row1.type)?{id:modalBody.row1.type}:undefined),
    				parent:((modalBody && modalBody.row3 && modalBody.row3.parent)?((modalBody.row3.parent.id)?{id:modalBody.row3.parent.id}:{id:modalBody.row3.parent}):undefined),
    				presetSignCircuit:((modalBody && modalBody.row3 && modalBody.row3.presetSignCircuit)?{id:modalBody.row3.presetSignCircuit}:undefined),
					councillorProposalTypes:[]
				};
				if(councillor.annexaFormly.fields && councillor.annexaFormly.fields.length > 0 && councillor.annexaFormly.fields[0] && councillor.annexaFormly.fields[0].fieldGroup){
					var proposalTypesField = $linq(councillor.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'proposalTypes'");
			        if(proposalTypesField && proposalTypesField.data && proposalTypesField.data.councillorProposalTypes){
						var pts = angular.copy(proposalTypesField.data.councillorProposalTypes);
						_.forEach(pts, function(pt){
							var ptAux = {
								proposalType:((pt.proposalType && pt.proposalType.id)?{id:pt.proposalType.id}:undefined),
								presetSignCircuit:((pt.presetSignCircuit && pt.presetSignCircuit.id)?{id:pt.presetSignCircuit.id}:undefined),
								councillorProposalTypeDelegationType:((pt.councillorProposalTypeDelegationType && pt.councillorProposalTypeDelegationType.id)?{id:pt.councillorProposalTypeDelegationType.id}:undefined),
								councillorProposalTypeDelegationOrgans:[],
								councillorProposalTypeDelegationDecrees:[]
							};
							if(pt.councillorProposalTypeDelegationOrgans && pt.councillorProposalTypeDelegationOrgans.length > 0){
								_.forEach(pt.councillorProposalTypeDelegationOrgans, function(organ){
									var organAux = {
										organ:((organ.organ && organ.organ.id)?{id:organ.organ.id}:undefined),
										delegated:((organ.delegated)?true:false),
										councillorProposalTypeDelegationOrganDelegationTypes:[]
									}
									if(organ.councillorProposalTypeDelegationOrganDelegationTypes && organ.councillorProposalTypeDelegationOrganDelegationTypes.length > 0){
										_.forEach(organ.councillorProposalTypeDelegationOrganDelegationTypes, function(delType){
											if(delType && delType.councillorProposalTypeDelegationType && delType.councillorProposalTypeDelegationType.id){
												organAux.councillorProposalTypeDelegationOrganDelegationTypes.push({councillorProposalTypeDelegationType:{id:delType.councillorProposalTypeDelegationType.id}});
											}
										})
									}
									ptAux.councillorProposalTypeDelegationOrgans.push(organAux);
								})
							}
							if(pt.councillorProposalTypeDelegationDecrees && pt.councillorProposalTypeDelegationDecrees.length > 0){
								_.forEach(pt.councillorProposalTypeDelegationDecrees, function(decree){
									var decreeAux = {
										decreeDate:decree.decreeDate,
										decreeNumber:decree.decreeNumber,
										decreeOfficialDiary:decree.decreeOfficialDiary
									}
									ptAux.councillorProposalTypeDelegationDecrees.push(decreeAux);
								})
							}
							model.councillorProposalTypes.push(ptAux);
						});
			        }
				}
				AdminFactory.newCouncillor(model).then(function (data) {
					$scope.councillors = [];
                    var councillorsAux = CommonService.getNestableData(((data)?data:[]), 'councillor','orderView');
					if(councillorsAux){
						_.forEach(councillorsAux, function(cAux){
							$scope.councillors.push(cAux);
						});
					}
					councillor.close();
                }).catch(function (error) {
                });
				
			}
		}
		
        $scope.viewCouncillor = function (councillor) {
        	var getSon = function(cp, initial){
        		if(cp && cp.id && initial && initial.id && initial.id === cp.id){
        			return cp;
        		}else{
        			var aux = {};
        			if(initial && initial.parent && initial.parent.id){
        				if(initial.parent.id === cp.id){
        					aux = initial;
        				}else{
        					aux = getSon(cp, initial.parent);
        				}
        			}
        			return aux;
        		}
        	}
        	
        	var nodesInside = function(node, c, initial){
        		if(node && node.length > 0){
    				if(c.id == initial.parent.id){
						return node;
					}else{
						var cp = getSon(c,initial);
						if(cp && cp.id){
							var nodeAux = $linq(node).firstOrDefault(undefined, "x => x.councillor && x.councillor.id == "+cp.id);
							if(nodeAux && nodeAux.nodes){
								return nodesInside(nodeAux.nodes, cp, initial);
							}else{
								return undefined;
							}
						}else{
							return undefined;
						}
					}
    			}else{
        			return undefined;
        		}
        	}
        	
        	var getParentNode = function(c, initial){
        		if(c && c.parent && c.parent.id){
        			var nodes = getParentNode(c.parent, initial);
        			if(nodes && nodes.length > 0){
        				if(!$linq(nodes).firstOrDefault(undefined, "x => x.councillor && x.councillor.id == "+initial.id)){
        					var node = $linq(nodes).firstOrDefault(undefined, "x => x.councillor && x.councillor.id == "+c.id);
	        				if(node && node.nodes){
	        					if(c.id === initial.id){
	        						return node.nodes;
	        					}else{
	        						return nodesInside(node.nodes, c, initial);
	        					}
	        				}else{
	        					return undefined;
	        				}
        				}else{
        					return nodes;
        				}
        			}else{
        				return undefined;
        			}
        		}else{
        			var node =  $linq($scope.councillors).firstOrDefault(undefined, "x => x.councillor && x.councillor.id == "+c.id);
        			if(node && node.nodes){
        				return node.nodes;
        			}else{
        				return undefined;
        			}
        		}
        	}
        	var getMaxOrderView = function(c){
        		if(c){
	        		if(c && c.parent && c.parent.id){
	        			var nodes = getParentNode(c.parent, c);
	        			var max = 1;
	        			if(nodes && nodes.length > 0){
		        			max = $linq(nodes).max("x => x.councillor.orderView");
	        			}
	        			return max;
	        		}else if(c){
	        			var max = 1;
	        			if($scope.councillors && $scope.councillors.length > 0){
		        			max = $linq($scope.councillors).max("x => x.councillor.orderView");
	        			}
	        			return max;
	        		}	
        		}else{
        			return 1;
        		}
        	}
            var modal = angular.copy(CommonAdminModals.councillorEdit);
            modal.alerts = [];
            modal.data = councillor;
            modal.languageColumn = $scope.langColumn;
            modal.updateCouncillor = $scope.updateCouncillor;
            modal.councillorTypes = AdminFactory.councillorTypes;
            modal.councillors = AdminFactory.councillors;
            modal.positions = AdminFactory.positions;
            modal.councillors = angular.copy(AdminFactory.councillorsList);
            modal.presetSignCircuits = angular.copy(GlobalDataFactory.presetSignCircuit);
            try{
            	modal.maxOrderView = getMaxOrderView(councillor);
            }catch(e){
            	modal.maxOrderView = 100;
            }
			modal.councillorProposalTypeActive = (($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.councillor_proposal_type && $rootScope.app.configuration.councillor_proposal_type.active)?true:false);
			modal.loadFunction = function(value) {
                if(!value) {
                    return [];
                }

                if(value != '*' && value.length < 3) {
                    return [];
                }

                if(value == '*') {
                    value = '';
                }

                return RestService.loadData('common', 'User', value)
                    .then(function(data) {
                        var users = [];

                        if(data.data && data.data.content && data.data.content.length > 0) {
                            _.forEach(JSOG.decode(data.data.content), function(val) {
                                var name = val.name + ' ' + val.surename1;

                                if(val.surename2) {
                                    name += ' ' + val.surename2;
                                }

                                users.push({ 'id':  val.id, 'user': val, 'value': name });
                            });
                        }

                        return users;
                    }).catch(function() {
                        return [];
                    });

            }
			var councilManAux = { user: undefined };
            if(modal.data.councilMan) {
                councilManAux = { user: { id: modal.data.councilMan.id, user: modal.data.councilMan, value: ((modal.data.councilMan.completeName)?modal.data.councilMan.completeName:'') }};
            }
            modal.councilManAux = councilManAux;
            AnnexaModalFactory.showModal('modalCouncillorEdit', modal);
        };

        $scope.deleteCouncillor = function(councillor) {
            DialogsFactory.confirm('global.commonAdmin.literals.deleteCouncillor', 'global.commonAdmin.literals.confirmDeleteCouncillor')
			.then(function (dataAux) {
	            AdminFactory.deleteCouncillor(councillor).then(function(data){
                    if($scope.councillors){
						$scope.councillors.length = 0;
					}else{
						$scope.councillors = [];
					}
                    var councillorsAux = CommonService.getNestableData(AdminFactory.councillorsList, 'councillor','orderView');
					if(councillorsAux){
						_.forEach(councillorsAux, function(cAux){
							$scope.councillors.push(cAux);
						});
					}
                }).catch(function (error) {
                	DialogsFactory.error(ErrorFactory.getErrorMessage('councillor', 'create', error.data));
	            });
            }).catch(function (data) {
            	deferred.reject(false);
            }); 
        };

        $scope.updateCouncillor = function (val, prop) {
			var self = this;
            var model = angular.copy(this.data);
            if(prop == 'language1' || prop == 'language2' || prop == 'language3') {
                model = angular.copy(val);
				this.data = val;
            } else {
				if(prop === 'type' || prop === 'councilManPosition' || prop === 'parent' || prop === 'presetSignCircuit'){
	                model[prop] = ((val && val.id)?{id:val.id}:undefined);
					this.data[prop] = val;
				}else if(prop === 'councilMan'){
					model[prop] = ((val && val.user && val.user.id)?{id:val.user.id}:undefined);
					this.data[prop] = val;
				}else if(prop === 'orderView'){
					model[prop] = val;
					this.data[prop] = val;
				}
            }
			model.councilMan = (( model.councilMan && model.councilMan.id)?{id:model.councilMan.id}:((model.councilMan.user && model.councilMan.user.id)?{id:model.councilMan.user.id}:undefined)),
			model.councilManPosition = ((model.councilManPosition && model.councilManPosition.id)?{id:model.councilManPosition.id}:undefined),
			model.type = ((model.type && model.type.id)?{id:model.type.id}:undefined),
    		model.parent = ((model.parent && model.parent.id)?{id:model.parent.id}:undefined),
    		model.presetSignCircuit = ((model.presetSignCircuit && model.presetSignCircuit.id)?{id:model.presetSignCircuit.id}:undefined),
			model.councillorProposalTypes = undefined;
			if(prop === 'parent' || prop === 'orderView'){
				if(prop === 'parent'){
					model.orderView = undefined;
				}
				AdminFactory.updateOrderCouncillor(model).then(function(data) {
					$scope.councillors = [];
	                var councillorsAux = CommonService.getNestableData(((data)?data:[]), 'councillor','orderView');
					if(councillorsAux){
						_.forEach(councillorsAux, function(cAux){
							$scope.councillors.push(cAux);
						});
					}
					self.close();
                }).catch(function (error) {
                	self.alerts.push({ msg: ErrorFactory.getErrorMessage('councillor', 'create', error.data) });
	            });
			}else{
				AdminFactory.updateCouncillor(model).then(function(data) {
	                $scope.councillors.length = 0;
					var councillorsAux = CommonService.getNestableData(((data)?data:[]), 'councillor','orderView');
					if(councillorsAux){
						_.forEach(councillorsAux, function(cAux){
							$scope.councillors.push(cAux);
						});
					}
	            }).catch(function (error) {
	            	self.alerts.push({ msg: ErrorFactory.getErrorMessage('councillor', 'create', error.data) });
	            });
			}
        };

        $scope.treeOptions = {
            beforeDrop : function (e) {
            	var deferred = $q.defer();            	
            	DialogsFactory.confirm('global.commonAdmin.literals.moveCouncillor', 'global.commonAdmin.literals.confirmMoveCouncillor').then(function (dataAux) {
	                var sourceValue = e.source.nodeScope.$modelValue.councillor;
	                var sourceParentValue = null;
	                if( e.source.nodeScope.$modelValue.councillor.parent != null &&  e.source.nodeScope.$modelValue.councillor.parent != undefined) {
	                    sourceParentValue = e.source.nodeScope.$modelValue.councillor.parent.id;
	                }
	                var destValue = e.dest.nodesScope.node ? e.dest.nodesScope.node.councillor: null;
	                if(destValue != null && destValue != undefined) {
                    	sourceValue.parent = destValue;
	                }else {
	                    sourceValue.parent = null;
	                }
	                if(e.dest != null){
	                	sourceValue.orderView = e.dest.index+1;
	                }
					AdminFactory.updateOrderCouncillor(sourceValue).then(function(data) {
						$scope.councillors = [];
						var councillorsAux = CommonService.getNestableData(((data)?data:[]), 'councillor','orderView');
						if(councillorsAux){
							_.forEach(councillorsAux, function(cAux){
								$scope.councillors.push(cAux);
							});
						}
                    	deferred.resolve(true);
	                }).catch(function (error) {
	                	DialogsFactory.error(ErrorFactory.getErrorMessage('councillor', 'create', error.data));
                		deferred.resolve(false);
		            });
            	}).catch(function (data) {
                	deferred.reject(false);
	            });  
            	
            	return deferred.promise;	
            }
        };

	
	
        $scope.deleteCouncillor = function(id) {
            DialogsFactory.confirm('global.commonAdmin.literals.deleteCouncillor','global.commonAdmin.literals.confirmDeleteCouncillor').then(function (btn) {
                AdminFactory.deleteCouncillor(id).then(function (data) {
                    $scope.doSearchFilter();
                }).catch(function (error) {
                });
            }).catch(function (data) {
            });  
        };
    }])
    .controller('CouncillorTypeAdminController', ['$scope', '$state', '$filter', 'DTColumnBuilder', '$rootScope', 'TableFilter', 'HeaderService', 'Language', 'CommonAdminModals', 'AnnexaFormlyFactory', 'AdminFactory', 'RestService', 'AnnexaModalFactory', 'dialogs', 
		function ($scope, $state, $filter, DTColumnBuilder, $rootScope, TableFilter, HeaderService, Language, CommonAdminModals, AnnexaFormlyFactory, AdminFactory, RestService, AnnexaModalFactory, dialogs) {
			$scope.newCouncillorType = CommonAdminModals.councillorTypeNew;
	    	var levelRender = function(data, type, object, meta) {
				if(data){
					if(data === 'LEVEL1'){
						return $filter('translate')('global.sec.literals.level1');
					}else if(data === 'LEVEL2'){
						return $filter('translate')('global.sec.literals.level2');
					}else if(data === 'LEVEL3'){
						return $filter('translate')('global.sec.literals.level3');
					}
				}
                return "";
            };

    	 	$scope.saveCouncillorType = function(){
    	 		if (this.annexaFormly.form.$valid) {
                	var self = this;
                	var data = this.annexaFormly.model.modal_body;
                	var sendData = {
                			language1:((data && data.language1)?data.language1:'NotTranslated'),
                			language2:((data && data.language2)?data.language2:'NotTranslated'),
                			language3:((data && data.language3)?data.language3:'NotTranslated'),
                			finalType:((data && data.finalType)?data.finalType:false),
 							contentLevel:((data && data.contentLevel)?data.contentLevel:'LEVEL3')
                	}
                    RestService.insert('./api/councillor_type', sendData).then(function (dataGet) {
                    	$rootScope.$broadcast('annexaAdminBaseReloadTable');
                    	self.close();
                    }).catch(function (error) {
                    	self.alerts.push({ msg: $filter('translate')('global.commonAdmin.literals.errorCreate') });
                    });
                }
            };
            
            $scope.filter = [
            	{ id: Language.getActiveColumn(), type: 'text', order: 1, label: 'global.literals.name' },
				{ id: 'finalType', type: 'checkbox', order: 1, label: 'global.literals.finalType'}
            ]
            $scope.additionalColumns = [
				{ id: 'finalType', column: new BooleanColumn($filter, 'global.literals.finalType', 'finalType'), sortable: false },
				{ id: 'contentLevel', title: $filter('translate')('global.sec.literals.level'), render: levelRender, sortable: false}
            ]

            $scope.viewElement = function (id) {
                if(id) {
                	RestService.findOne('CouncillorType', id).then(function(data) {
                        var modal = angular.copy(CommonAdminModals.councillorTypeEdit);
                        modal.data = JSOG.decode(data.data);
						if(modal.data.log){
							modal.data.log = undefined;
						}
						modal.otherLangColumn = "description";
						modal.levels = [ { id: 'LEVEL1', description: $filter('translate')('global.sec.literals.level1') }, { id: 'LEVEL2', description: $filter('translate')('global.sec.literals.level2') }, { id: 'LEVEL3', description: $filter('translate')('global.sec.literals.level3') }];
                        modal.alerts = [];
                        modal.languageColumn = Language.getActiveColumn();
                        modal.printFinalType = function(){
                            if(modal && modal.data && modal.data.finalType){
								return $filter('translate')('global.literals.yes');
							}else{
								return $filter('translate')('global.literals.no');		
							}
                        }
                        modal.printContentLevel = function(){
                            if(modal && modal.data && modal.data.contentLevel){
								if(modal.data.contentLevel === 'LEVEL1' || (modal.data.contentLevel.id && modal.data.contentLevel.id === 'LEVEL1')){
									return $filter('translate')('global.sec.literals.level1');
								}else if(modal.data.contentLevel === 'LEVEL2' || (modal.data.contentLevel.id && modal.data.contentLevel.id === 'LEVEL2')){
									return $filter('translate')('global.sec.literals.level2');
								}else if(modal.data.contentLevel === 'LEVEL3' || (modal.data.contentLevel.id && modal.data.contentLevel.id === 'LEVEL3')){
									return $filter('translate')('global.sec.literals.level3');
								}else{
									return "";
								}
							}else{
								return "";		
							}
                        }
						modal.updateCouncillorType = $scope.updateCouncillorType;
                        AnnexaModalFactory.showModal('modalCouncillorTypeEdit', modal);
                    });
                }
            };
    	   
            $scope.updateCouncillorType = function(val, prop) {
            	var councillorType = angular.copy(this.data);
            	var self = this;
            	self.alerts = [];
				if(prop === 'finalType'){
					councillorType.finalType = val;
				}else if(prop === 'contentLevel'){
					councillorType.contentLevel = ((val && val.id)?val.id:undefined);
				}
				if(councillorType.contentLevel && councillorType.contentLevel.id){
					councillorType.contentLevel = councillorType.contentLevel.id;
				}
            	RestService.update('./api/councillor_type/'+councillorType.id, councillorType).then(function (dataGetDocument) {
                	$rootScope.$broadcast('annexaAdminBaseReloadTable');
               }).catch(function (error) {
                	self.alerts.push({ msg: $filter('translate')('global.commonAdmin.literals.errorCreate') });
                });
            };            
    }])
	.controller('DelegationTypeController',['$scope', '$http', '$rootScope', 'CommonAdminModals', 'Language', '$filter', 'RestService', 'AnnexaModalFactory', 'AdminFactory', 'GlobalDataFactory', 'CacheFactory', 'globalModals', 'AnnexaFormlyFactory', 
        	function ($scope, $http, $rootScope, CommonAdminModals, Language, $filter, RestService, AnnexaModalFactory, AdminFactory, GlobalDataFactory, CacheFactory, globalModals, AnnexaFormlyFactory) {
    	 	$scope.newDelegationType = CommonAdminModals.delegationTypeNew;
    	
    	 	$scope.saveDelegationType = function(){
    	 		if (this.annexaFormly.form.$valid) {
                	var self = this;
                	var data = this.annexaFormly.model.modal_body;
                	var sendData = {
                			language1:((data && data.language1)?data.language1:'NotTranslated'),
                			language2:((data && data.language2)?data.language2:'NotTranslated'),
                			language3:((data && data.language3)?data.language3:'NotTranslated'),
                			decreeMessageLanguage1:((data && data.decreeMessageLanguage1)?data.decreeMessageLanguage1:'NotTranslated'),
                			decreeMessageLanguage2:((data && data.decreeMessageLanguage2)?data.decreeMessageLanguage2:'NotTranslated'),
                			decreeMessageLanguage3:((data && data.decreeMessageLanguage3)?data.decreeMessageLanguage3:'NotTranslated'),
                			decreesMessageLanguage1:((data && data.decreesMessageLanguage1)?data.decreesMessageLanguage1:'NotTranslated'),
                			decreesMessageLanguage2:((data && data.decreesMessageLanguage2)?data.decreesMessageLanguage2:'NotTranslated'),
                			decreesMessageLanguage3:((data && data.decreesMessageLanguage3)?data.decreesMessageLanguage3:'NotTranslated')
                	}
                    RestService.insert('./api/councillor_proposal_type_delegation_type', sendData).then(function (dataGet) {
                    	$rootScope.$broadcast('annexaAdminBaseReloadTable');
                    	self.close();
                    }).catch(function (error) {
                    	self.alerts.push({ msg: $filter('translate')('global.commonAdmin.literals.errorCreate') });
                    });
                }
            };
            
            $scope.filter = [
            	{ id: Language.getActiveColumn(), type: 'text', order: 1, label: 'global.literals.name' },
            	{ id: 'decreeMessageL'+Language.getActiveColumn().substring(1), type: 'text', order: 1, label: 'global.commonAdmin.literals.decreeMessageLanguage' },
				{ id: 'decreesMessageL'+Language.getActiveColumn().substring(1), type: 'text', order: 1, label: 'global.commonAdmin.literals.decreesMessageLanguage' }
            ]

            $scope.additionalColumns = [
                { id: 'decreeMessageL'+Language.getActiveColumn().substring(1), title: $filter('translate')('global.commonAdmin.literals.decreeMessageLanguage')},
				{ id: 'decreesMessageL'+Language.getActiveColumn().substring(1), title: $filter('translate')('global.commonAdmin.literals.decreesMessageLanguage')}
            ]

            $scope.viewElement = function (id) {
                if(id) {
                	RestService.findOne('CouncillorProposalTypeDelegationType', id).then(function(data) {
                        var modal = angular.copy(CommonAdminModals.delegationTypeEdit);
                        modal.data = JSOG.decode(data.data);
						if(modal.data.log){
							modal.data.log = undefined;
						}
                        modal.alerts = [];
                        modal.languageColumn = Language.getActiveColumn();
                        modal.decreeMessageLanguageColumn = "decreeMessageL"+modal.languageColumn.substring(1);
                        modal.decreesMessageLanguageColumn = "decreesMessageL"+modal.languageColumn.substring(1);
                        modal.printDecree = function(){
                            if(modal.data && modal.data[ modal.decreeMessageLanguageColumn]){
                                return modal.data[modal.decreeMessageLanguageColumn];
                            }
                            return '';
                        }
						modal.printDecrees = function(){
                            if(modal.data && modal.data[ modal.decreesMessageLanguageColumn]){
                                return modal.data[modal.decreesMessageLanguageColumn];
                            }
                            return '';
                        }
                        modal.updateDelegationType = $scope.updateDelegationType;
                        AnnexaModalFactory.showModal('modalDelegationTypeEdit', modal);
                    });
                }
            };
    	   
            $scope.updateDelegationType = function(val, prop) {
            	var delegationType = angular.copy(this.data);
            	var self = this;
            	self.alerts = [];
				
            	RestService.update('./api/councillor_proposal_type_delegation_type/'+delegationType.id, delegationType).then(function (dataGetDocument) {
                	$rootScope.$broadcast('annexaAdminBaseReloadTable');
               }).catch(function (error) {
                	self.alerts.push({ msg: $filter('translate')('global.commonAdmin.literals.errorCreate') });
                });
            };
            
        }]);
