/**
 * Created by osirvent on 20/09/2016.
 */
angular
    .module('annexaApp')
    .factory('AdminFactory',['$http', '$q', 'Language', '$rootScope', 'CommonService', 'apiAdmin', '$translate', '$filter', 'RestService', 'GlobalDataFactory', 'HelperService', 'DialogsFactory', 'CommonAdminModals', 'AnnexaModalFactory', 'AnnexaFormlyFactory', 'globalModals', 'QueryFactory', function ($http, $q, Language, $rootScope, CommonService, apiAdmin, $translate, $filter, RestService, GlobalDataFactory, HelperService, DialogsFactory, CommonAdminModals, AnnexaModalFactory, AnnexaFormlyFactory, globalModals, QueryFactory) {
        var factory = {};

        factory.procedures = [];
        factory.templateTypes = [];
        factory.sections = [];
        factory.sectionTypes = [];
        factory.councillors = [];
        factory.councillorsList = [];
        factory.users = [];
        factory.roles = [];
        factory.profiles = [];
        factory.rolesInterested = [];
        factory.signActionTypes = [];
        factory.signatoryTypes = [];
        factory.actionPermissions = [];
        factory.roleEdit = {};
        factory.procedure = undefined;
        factory.customFields = [];
        factory.subprocedures = [];
        factory.configurationGroups = [];
		factory.configurations = [];
        factory.tramitationPhases = [];
        factory.tramitationTypes = [];
        factory.transactionTypes = [];
        factory.transactionTypesOrdinary = [];
        factory.positions = [];
        factory.politicalParties = [];
        factory.entityEvents = [];
        factory.globalPresetSignCircuits = [];
        factory.aspectEdit = {}
        factory.queryParameters = [];
        factory.queryTablesInformation = [];
        factory.queryConnectors = [];
        factory.accountInstances = [];
        factory.taskTypes = [];
        factory.projectEntities = [];
        factory.councillorTypes = [];
		factory.councillorProposalTypeDelegationType = [];
		factory.actionCatalogs = [];
		factory.allQueries = [];
		
        factory.newPosition = function (position) {
            var deferred = $q.defer();

            $http({
                url: './api/admin/position',
                method: 'POST',
                data: JSOG.encode(position)
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.checkDelete = function(position) {
            var deferred = $q.defer();

            $http({
                url: './api/admin/position/check_delete',
                method: 'GET',
                params: { id: position }
            }).then(function(data) {
                deferred.resolve(data.data);
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.deletePosition = function(position) {
            var deferred = $q.defer();

            $http({
                url: './api/admin/position',
                method: 'DELETE',
                params: { id: position }
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }

        factory.getRoles = function () {
            return $http({
                method: 'GET',
                url: './api/admin/role'
            }).then(function (data, status) {
                var decodedData = JSOG.decode(data.data);
                factory.actionPermissions = decodedData;
            }).catch(function (err) {
            })
        }

        factory.newRole = function (role) {
            var deferrend = $q.defer();

            $http({
                url: './api/admin/role',
                method: 'POST',
                data: JSOG.encode(role)
            }).then(function (data) {
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error)
            });

            return deferrend.promise;
        }

        factory.updateRole = function (model) {
            var deferrend = $q.defer();

            $http({
                method: 'PUT',
                url: './api/admin/role',
                data: JSOG.encode(model)
            }).then(function (data) {
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        }
        
        factory.newRoleInterested = function (role) {
            var deferrend = $q.defer();

            $http({
                url: './api/admin/roleInterested',
                method: 'POST',
                data: JSOG.encode(role)
            }).then(function (data) {
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error)
            });

            return deferrend.promise;
        }
        
        factory.updateRoleInterested = function (model) {
            var deferrend = $q.defer();

            $http({
                method: 'PUT',
                url: './api/admin/roleInterested',
                data: JSOG.encode(model)
            }).then(function (data) {
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        }

        factory.getPositions = function () {
            return RestService.findAll('Position')
                .then(function(data) {
                    factory.positions = JSOG.decode(data.data);
                }).catch(function (error) {
                });
        }

        factory.getPoliticalParties = function() {
			var promises = [];
            promises.push(RestService.findAll('PoliticalParty'));
            promises.push(RestService.findAll('CouncillorProposalTypeDelegationType'));
            return $q.all(promises)
            .then(function(data) {
                factory.politicalParties = JSOG.decode(data[0].data);
				factory.councillorProposalTypeDelegationType = JSOG.decode(data[1].data);
            }).catch(function(error) {
                factory.politicalParties = [];
				factory.councillorProposalTypeDelegationType = [];
            });
        }

        factory.getUsers = function() {
            return $http({
                method: 'GET',
                url: './api/users'
            }).then(function (data, status) {
                var decodedData = JSOG.decode(data.data);
                factory.sections = decodedData.sections;
                factory.roles = decodedData.roles;
                factory.profiles = decodedData.profiles;
                factory.positions = decodedData.positions;
            }).catch(function (error) {
            });
        };

        factory.resetPassword = function(id, translations) {
            var deferred = $q.defer();

            $http({
                url: './api/users/reset_password/' + id,
                method: 'PUT',
                data: translations
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data))
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        factory.newUser = function (user) {
            var deferrend = $q.defer();

            $http({
                url: './api/users',
                method: 'POST',
                data: JSOG.encode(user)
            }).then(function (data) {
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.updateUser = function (model, certificate, photo) {
            var deferrend = $q.defer();
            var send = {user: model};
            if(certificate){
                send.certificate = certificate;
            }
            if(photo){
                send.photo = photo;
            }
            $http({
                method: 'PUT',
                url: './api/users',
                data: JSOG.encode(send)
            }).then(function (data) {
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        }

        factory.updatePhotoUser = function (model,photo) {
            var deferrend = $q.defer();
            if(model.id && photo) {
                $http({
                    method: 'PUT',
                    url: './api/users/photo/' + model.id,
                    data: JSOG.encode(photo)
                }).then(function (data) {
                    deferrend.resolve(data);
                }).catch(function (error) {
                    deferrend.reject(error);
                });
            }else{
                deferrend.reject(error);
            }
            return deferrend.promise;
        }

        factory.deleteTemplateType = function (template) {
            var deferrend = $q.defer();

            $http({
                url: './api/doc/template_type/' + template.id + '/return_all',
                method: 'DELETE'
            }).then(function (data) {
                var decodedData = JSOG.decode(data.data);
                factory.templateTypes = decodedData;
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.newTemplateType = function (template) {
            var deferrend = $q.defer();

            $http({
                url: './api/doc/template_type/return_all',
                method: 'POST',
                data: JSOG.encode(template)
            }).then(function (data) {
                var decodedData = JSOG.decode(data.data);
                factory.templateTypes = decodedData;
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.deleteSection = function (section) {
            var deferrend = $q.defer();

            $http({
                url: './api/sections',
                method: 'DELETE',
                params: { sectionId: section.id }
            }).then(function (data) {
                var decodedData = JSOG.decode(data.data);
                factory.sections = decodedData;
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.newSection = function(section) {
            var deferrend = $q.defer();

            $http({
                url: './api/sections',
                method: 'POST',
                data: JSOG.encode(section)
            }).then(function (data) {
                var decodedData = JSOG.decode(data.data);
                factory.sections = decodedData;
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.getSections = function () {
            return $http({
                method: 'GET',
                url: './api/sections'
            }).then(function(data, status) {
                var decodedData = JSOG.decode(data.data);
                factory.sections = decodedData.sections;
                factory.sectionTypes = decodedData.sectionTypes;
                factory.councillors = decodedData.councillors;
                factory.users = [];
                angular.forEach(decodedData.users, function (value, key) {
                    value.completeName = ((value.name || '') + ' ' + (value.surename1 || '') + ' ' + (value.surename2 || '')).trim();
                    factory.users.push(value);
                });
            }).catch(function (error) {
            });
        };

		factory.getCouncillors = function () {
			var promises = [];
            promises.push(RestService.findAll('Councillor'));
            promises.push(RestService.findAll('Position'));
			promises.push(RestService.findAll('CouncillorType'));
			promises.push(RestService.findAll('CouncillorProposalTypeDelegationType'));
            return $q.all(promises)
            .then(function(data) {
                factory.councillorsList = JSOG.decode(data[0].data);
				factory.positions = JSOG.decode(data[1].data);
				factory.councillorTypes = JSOG.decode(data[2].data);
				factory.councillorProposalTypeDelegationType = JSOG.decode(data[3].data);
            }).catch(function(error) {
                factory.councillorsList = [];
 				factory.positions = [];
				factory.councillorTypes = [];
				factory.councillorProposalTypeDelegationType = [];
            });
        };

        factory.deleteCouncillor = function (councillor) {
			var deferrend = $q.defer();
            $http({
                url: './api/councillors/'+councillor.id,
                method: 'DELETE',
            }).then(function (data) {
                var decodedData = JSOG.decode(data.data);
                factory.councillorsList = decodedData;
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;            
        }

        factory.newCouncillor = function (councillor) {
			 var deferrend = $q.defer();

            $http({
                url: './api/councillors',
                method: 'POST',
                data: JSOG.encode(councillor)
            }).then(function (data) {
                var decodedData = JSOG.decode(data.data);
                factory.councillorsList = decodedData;
                deferrend.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;            
        };
		factory.updateCouncillor = function (councillor) {
            var deferred = $q.defer();
            $http({
                url: './api/councillors',
                method: 'PUT',
                data: JSOG.encode(councillor)
            }).then(function (data) {
                var decodedData = JSOG.decode(data.data);
                factory.councillorsList = decodedData;
                deferred.resolve(decodedData);
            }).catch(function(error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }
		factory.updateOrderCouncillor = function (councillor) {
			 var deferrend = $q.defer();

            $http({
                url: './api/councillors/saveOrder',
                method: 'PUT',
                data: JSOG.encode(councillor)
            }).then(function (data) {
            	var decodedData = JSOG.decode(data.data);
                factory.councillorsList = decodedData;
                deferrend.resolve(decodedData);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;            
        };

        factory.getTemplateTypes = function() {
            return $http({
                url: './api/doc/template_type',
                method: 'GET'
            }).then(function (data) {
                var decodedData = JSOG.decode(data.data);
                factory.templateTypes = decodedData;
            }).catch(function (error) {
            });
        };

        factory.newDocTemplate = function () {
            var promises = [];

            promises.push(RestService.findAll('TemplateType'));
            
            return $q.all(promises)
                .then(function(data) {
                    factory.templateTypes = JSOG.decode(data[0].data);
                }).catch(function(error) {
                    factory.templateTypes = [];
                });
        };

        factory.updateDocTemplate = function (model) {
            var deferred = $q.defer();

            RestService.update('./api/doc/template/' + model.id, model)
                .then(function(data) {
                    deferred.resolve(data);
                }).catch(function(error) {
                    deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.updateTramTemplate = function (model) {
            var deferrend = $q.defer();

            $http({
                method: 'PUT',
                url: './api/tram/template',
                data: JSOG.encode(model)
            }).then(function(data) {
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        }

        factory.addTramTemplate = function (model, files) {
            var deferrend = $q.defer();

            var formData = new FormData();
            if(Array.isArray(files)){
            	_.forEach(files, function(file, index){
            		formData.append('files', file);
            	});
            }else{
            	formData.append('files', files);
            }
            formData.append('model', new Blob([JSON.stringify(model)], {
                type: "application/json"
            }));

            $http({
                method: 'POST',
                url: './api/tram/template',
                headers: {'Content-Type': undefined},
                data: formData,
                transformRequest: function(data, headersGetterFunction) {
                    return data;
                }
            }).success(function (data, status) {
                deferrend.resolve(data);
            }).error(function (msg, code) {
                deferrend.reject(msg);
            });

            return deferrend.promise;
        }

        factory.updateTramTemplate = function (model) {
            var deferrend = $q.defer();

            $http({
                method: 'PUT',
                url: './api/tram/template',
                data: JSOG.encode(model)
            }).then(function(data) {
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        }

        factory.getTramFieldsMetadataXML = function(window,json) {
            var deferrend = $q.defer();

            if(!json) {
                json = false;
            }

            $http({
                method: 'GET',
                url: './api/tram/fromtemplate',
                params: { json: json }
            }).success(function(data, status, headers) {
                headers = headers();

                var contentType = headers['content-type'];

                try {
					if(window && window.navigator && window.navigator.msSaveOrOpenBlob) {
                        if(json) {
							var blob = new Blob([JSON.stringify(data)], {type: contentType});
			            	deferrend.resolve(blob);
		                } else {
							var blob = new Blob([data], {type: contentType});
                        	deferrend.resolve(blob);
						}
                    } else {
                        if(json) {
							var blob = new Blob([JSON.stringify(data)], {type: contentType});
			            	var linkElement = document.createElement('a');
                			var url = window.URL.createObjectURL(blob);
	                        linkElement.setAttribute('href', url);
	                        linkElement.setAttribute('download', 'annexa.fields.json');
							deferrend.resolve(linkElement);		                
		                } else {
							var blob = new Blob([data], {type: contentType});
                        	var linkElement = document.createElement('a');
                			var url = window.URL.createObjectURL(blob);
	                        linkElement.setAttribute('href', url);
	                        linkElement.setAttribute('download', 'annexa.fields.xml');
							deferrend.resolve(linkElement);
						}
                    }
                    
                } catch(ex) {
                    deferrend.reject(ex);
                }
            }).error(function(error) {
                deferrend.reject(error);
            })

            return deferrend.promise;
        }

        factory.newPresetSignCircuit = function(){
            return $http({
                url: './api/presetsigncircuits/getPresetSignCircuitData',
                method: 'GET'
            }).then(function(data) {
                var presetData = JSOG.decode(data.data);
                factory.signActionTypes = presetData.signActionTypes;
                factory.signatoryTypes = presetData.signatoryTypes;
            }).catch(function (error) {
            });
        };

        factory.getConfigurationGroups = function () {
			var promises = [];
            promises.push(RestService.findAll('ConfigurationGroup'));
            promises.push(RestService.findAll('Configuration'));
			return $q.all(promises).then(function(data) {
                    factory.configurationGroups = JSOG.decode(data[0].data);
					factory.configurations = JSOG.decode(data[1].data);
                }).catch(function (error) {
					factory.configurationGroups = [];
					factory.configurations = [];
                });
        };

        factory.getCustomFields = function () {
        	var deferred = $q.defer();
        	factory.customFields = GlobalDataFactory.customFields;
            deferred.resolve('OK');
            return deferred.promise;
        };
        
        factory.getQueryParameters = function () {
            return RestService.findAll('QueryParameter')
                .then(function (data) {
                    factory.queryParameters = JSOG.decode(data.data);
                }).catch(function (error) {
                });
        };

        factory.updateConfiguration = function (key, value, show_folders_types) {
            var deferred = $q.defer();

            $http({
                url: './api/configuration',
                method: 'PUT',
                data: { key_name: key, value: value, show_folders_types: show_folders_types}
            }).then(function(data) {
				var indexConfig = $linq(factory.configurations).indexOf("x => x.key === '"+key+"'");
                if(indexConfig != -1) {
                	factory.configurations[indexConfig].value = value;
                }
                deferred.resolve(data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.updateConfigurations = function(configurations) {
            var deferred = $q.defer();

            $http({
                url: './api/admin/configuration',
                method: 'POST',
                data: configurations
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        factory.saveBPM = function (procedure) {
            var deferrend = $q.defer();

            $http({
                url: './api/admin/bpm/save',
                method: 'POST',
                data: JSOG.encode(procedure)
            }).then(function(data) {
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.getFamilyProceduresReload = function () {
            return RestService.findAll('FamilyProcedure')
                .then(function(data) {
                	GlobalDataFactory.familyProcedures = JSOG.decode(data.data);
                	GlobalDataFactory.familiesProcedureTree = CommonService.getTreeData(angular.copy(GlobalDataFactory.familyProcedures), Language.getActiveColumn());
                }).catch(function(error) {
                });
        }

        factory.newClassification = function (classification) {
            var deferrend = $q.defer();

            $http({
                url: './api/archiveClassifications/archiveClassification',
                method: 'POST',
                data: JSOG.encode(classification)
            }).then(function (data) {
                var decodedData = JSOG.decode(data.data);
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };
        factory.deleteClassification = function (classification) {
            var deferrend = $q.defer();

            $http({
                url: './api/archiveClassifications/archiveClassification',
                method: 'DELETE',
                params: { classificationId: classification.id }
            }).then(function (data) {
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.insertOrUpdateRegisterClassificationEdit = function (classification) {
            var deferrend = $q.defer();

            $http({
                url: './api/registerClassifications/registerClassification',
                method: 'POST',
                data: JSOG.encode(classification)
            }).then(function (data) {
                var decodedData = JSOG.decode(data.data);
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.insertOrUpdateTaskTemplate = function (taskTemplate) {
            var deferrend = $q.defer();

            $http({
                url: './api/taskTemplates/taskTemplate',
                method: 'POST',
                data: JSOG.encode(taskTemplate)
            }).then(function (data) {
                var decodedData = JSOG.decode(data.data);
                deferrend.resolve(decodedData);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.updateTaskTemplateCustomField = function(customField){
            var deferred = $q.defer();

            $http({
                method: 'PUT',
                url: './api/taskTemplates/' + customField.taskTemplate.id + '/custom_field',
                data: JSOG.encode(customField)
            }).success(function(data, status) {
                deferred.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferred.reject(msg);
            });

            return deferred.promise;
        };

        factory.deleteRegisterClassification = function (classification) {
            var deferrend = $q.defer();

            $http({
                url: './api/registerClassifications/registerClassification',
                method: 'DELETE',
                params: { classificationId: classification }
            }).then(function (data) {
                var decodedData = JSOG.decode(data.data);
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.deleteTaskTemplate = function (taskTemplate) {
            var deferrend = $q.defer();

            $http({
                url: './api/taskTemplates/taskTemplate',
                method: 'DELETE',
                params: { taskTemplateId: taskTemplate }
            }).then(function (data) {
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.setupGuidedProcedures = function (procedure) {
	 		var promises = [];
            promises.push($http({url: './api/admin/procedure/setup', method: 'GET', params: { procedure: procedure }}));
			promises.push($http({url: './api/admin/procedure/setupTransactionTransitionGuided', method: 'GET', params: { procedure: procedure }}));
			promises.push(RestService.findAll('CouncillorProposalTypeDelegationType'));
            if(procedure && procedure > 0){
				promises.push($http({url: './api/procedure/documentationProvice/byProcedure/'+procedure, method: 'GET'}));	
			}
            
            return $q.all(promises).then(function(data) {
                var decodedData = JSOG.decode(data[0].data);
                var families = decodedData.familyProcedures;
                families = $linq(families).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
                factory.profiles = decodedData.profiles;
                factory.procedure = decodedData.procedure;
                factory.subprocedures = decodedData.subprocedures;
                factory.tramitationTypes = decodedData.tramitationTypes;
                factory.transactionTypes = decodedData.transactionTypes;
                factory.rolesInterested = decodedData.rolesInterested;
                if(factory.transactionTypes && factory.transactionTypes.length > 0){
                	factory.transactionTypes = $linq(factory.transactionTypes).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
                }
                factory.transactionTypesOrdinary = $linq(decodedData.transactionTypes).where("x=>x.tramitationType.id == "+$rootScope.app.configuration.default_tramitation_type.value).toArray();
                if(factory.transactionTypesOrdinary && factory.transactionTypesOrdinary.length > 0){
                	factory.transactionTypesOrdinary = $linq(factory.transactionTypesOrdinary).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
                }
                factory.customFields = [];
                factory.customFields.push({ id:"-1", language1: $filter('translate')('global.literals.new'), language2: $filter('translate')('global.literals.new'), language3: $filter('translate')('global.literals.new')});
                var cf = $linq(decodedData.customFields).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
                _.forEach(cf, function (item) {
                    factory.customFields.push(item);
                });
                
                if(factory.procedure && factory.procedure.transactions && factory.procedure.transactions.length > 0) {
                	_.forEach(factory.procedure.transactions, function (procTransaction) {
                        if(procTransaction.transaction.guidedSubprocedure) {
                        	procTransaction.transaction.subprocedure = procTransaction.transaction.guidedSubprocedure;
                        }
                    });
                }
                
				var decocedDataTransactionTransition = JSOG.decode(data[1].data);
				if(factory.procedure && decocedDataTransactionTransition){
					factory.procedure.transactionTransitionGuideds = decocedDataTransactionTransition;
				}else if(factory.procedure){
					factory.procedure.transactionTransitionGuideds = [];
				}
				factory.councillorProposalTypeDelegationType = JSOG.decode(data[2].data);
	            if(procedure && procedure > 0){
					var documentationToProvide = JSOG.decode(data[3].data);
					if(factory.procedure && documentationToProvide){
						factory.procedure.documentationToProvide = documentationToProvide;
					}else if(factory.procedure){
						factory.procedure.documentationToProvide = [];
					}	
				}else{
					if(factory.procedure){
						factory.procedure.documentationToProvide = [];
					}
				}
            }).catch(function(error) {
            	factory.councillorProposalTypeDelegationType = [];
            });
        };

        factory.getTramitationPhases = function(){
            return $http({
                url: './api/tramitationPhases/phases',
                method: 'GET'
            }).then(function (data) {
                var decodedData = JSOG.decode(data.data);
                factory.tramitationPhases = decodedData;
            }).catch(function (error) {
            });
        };

        factory.getProcessForm = function () {
            var data = {
                row: true,
                colClass: 'col-xl-12 col-lg-12 col-md-12 col-sm-12',
                labelClass: 'label-strong'
            };

            var addClassificationFuntion = function(){
                var id = 0;

                if(this.optionAdd) {
                    var classification = {};
                    classification[Language.getActiveColumn()] = this.optionAdd;
                    classification.deleted = false;
                    if(form.model.procedureClassifications){
                        form.model.procedureClassifications.push(classification);
                    }else{
                        form.model.procedureClassifications = [];
                        form.model.procedureClassifications.push(classification);
                    }
                    this.optionAdd = '';
                }
            };

            var removeClassificationFuntion = function(index){
                if(form.model.procedureClassifications && form.model.procedureClassifications.length > index){
                    form.model.procedureClassifications.splice(index, 1);
                }
            };

            var form = new AnnexaFormly();
            form.addFieldGroup('basicinfo','accordionWrapper', new AnnexaFormlyFieldAccordionTemplateOptions('global.literals.basicInfo'),
                [
                    form.createField('language1', 'annexaInputLanguage', '', new AnnexaFormlyFieldLanguageTemplateOptions('language', '', 'global.literals.name', 'horizontal', true, false, true, $rootScope.app.languagedef), data),
                    form.createField('descriptionLanguage1','annexaInputLanguage','',new AnnexaFormlyFieldLanguageTemplateOptions('descriptionLanguage','descriptionLanguage','global.literals.description','horitzontal',true,false,true,$rootScope.app.languagedef),data),
                    form.createField('acronym','annexaInputRow','',new AnnexaFormlyFieldTemplateOptions('text','global.literals.acronym',false,false,undefined,20),data),
                    form.createField('maxDays','annexaInputRow','',new AnnexaFormlyFieldTemplateOptions('number','global.literals.days',true,false),data),
                    form.createField('showSubject','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.commonAdmin.modal.unguidedProcedure.showSubject','id','description',apiAdmin.showSubjectList,true),data)
                ]
            );

            form.addFieldGroup('classifications','accordionWrapper', new AnnexaFormlyFieldAccordionTemplateOptions('global.literals.classifications'),
                [
                    form.createField('family','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.family','id',Language.getActiveColumn(),GlobalDataFactory.familyProcedures,true),data),
                    form.createField('archiveClassification','annexaSelectTreeRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.classificationBox','id',Language.getActiveColumn(),GlobalDataFactory.archiveClassificationsTree,false),data),
                    form.createField('showClassification','annexaSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.commonAdmin.modal.unguidedProcedure.showClassification','id','description',apiAdmin.showClassificationList,true),data),
                    form.createField('procedureClassifications','annexaLanguageFieldSet','',new AnnexaFormlyFieldFieldSetTemplateOptions('global.literals.classification',undefined,'','global.commonAdmin.modal.unguidedProcedure.placeholderClassification',addClassificationFuntion,removeClassificationFuntion,'',true, false),data,'model.classifications.showClassification == \'DISABLED\'')
                ]
            );

            form.addFieldGroup('profiles','accordionWrapper', new AnnexaFormlyFieldAccordionTemplateOptions('global.literals.profiles'),
                [
                    form.createField('procedureStartProfiles','annexaMultipleSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.initialize','id',Language.getActiveColumn(),factory.profiles,true),data),
                    form.createField('procedureTramitationProfiles','annexaMultipleSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.tramitation','id',Language.getActiveColumn(),factory.profiles,true),data),
                    form.createField('procedureViewProfiles','annexaMultipleSelectRow','',new AnnexaFormlyFieldSelectTemplateOptions('global.literals.showProcedure','id',Language.getActiveColumn(),factory.profiles,true),data)
                ]
            );


            form.addFieldGroup('others','accordionWrapper', new AnnexaFormlyFieldAccordionTemplateOptions('global.literals.others'),
                [
                    form.createField('informationLanguage1','annexaInputLanguage','',new AnnexaFormlyFieldLanguageTemplateOptions('informationLanguage','informationLanguage','global.literals.information','horitzontal',false,false,true,$rootScope.app.languagedef),data)
                ]
            );

            var procedureInitials = {
                showClassification: "DISABLED",
                showSubject: "DISABLED",
                classification: {},
                procedureClassifications: [],
                archiveClassification: {}
            };

            form.model = new procedure(procedureInitials);

            return form;
        };
        
        factory.getEntityEvents = function() {
            var deferrend = $q.defer();
            if($rootScope.LoggedUser && $rootScope.LoggedUser.entity){
                factory.entityEvents = $rootScope.LoggedUser.entity;
                if(!factory.entityEvents.holidays){
                    factory.entityEvents.holidays = [];
                }
                deferrend.resolve(factory.entityEvents);
            }else if($rootScope.app.configuration.entity){
                RestService.findOne('Entity', $rootScope.app.configuration.entity.value).then(function(data) {
                    factory.entityEvents = JSOG.decode(data.data);
                    if(!factory.entityEvents.holidays){
                        factory.entityEvents.holidays = [];
                    }
                    deferrend.resolve(factory.entityEvents);
                }).catch(function (error) {
                });
            }
            return deferrend.promise;
        };

        factory.getQueryTablesInformation = function(connectorId){
        	var urlTablesInformation = './api/query/tablesInformation';
        	if (connectorId)
        		urlTablesInformation += '/' + connectorId;
            return $http({
                method: 'GET',
                url: urlTablesInformation
            }).then(function(data, status) {
                var decodedData = JSOG.decode(data.data);
                factory.queryTablesInformation = decodedData;
            }).catch(function (error) {
                factory.queryTablesInformation = [];
            });
        }

        factory.getQueryColumnsInformation = function(table, connectorId){
            var deferrend = $q.defer();
        	var urlColumnsInformation = './api/query/columnsInformation/' + table;
        	if (connectorId)
        		urlColumnsInformation += '/' + connectorId;
            $http({
                method: 'GET',
                url: urlColumnsInformation
            }).then(function(data, status) {
                var decodedData = JSOG.decode(data.data);
                deferrend.resolve(decodedData);
            }).catch(function (error) {
                deferrend.resolve([]);
            });
            return deferrend.promise;
        }
        
        factory.getQueryConnectors = function () {
    		return RestService.findAll('QueryConnector')
            .then(function (data) {
                factory.queryConnectors = JSOG.decode(data.data);
            }).catch(function (error) {
                factory.queryConnectors = [];
            });
        };
        
        factory.getAccountInstances = function() {
            return $http({
                method: 'GET',
                url: './api/admin/accountInstances'
            }).then(function (data, status) {
                var decodedData = JSOG.decode(data.data);
                factory.accountInstances = decodedData;
            }).catch(function (error) {
            });
        };
        
        factory.getTaskTypes = function() {
    		return RestService.findAll('TaskType', 'admin')
            .then(function (data) {
                factory.taskTypes = JSOG.decode(data.data);
            }).catch(function (error) {
                factory.taskTypes = [];
            });
        };
        
        factory.getImportTemplate = function(importType) {
            var deferrend = $q.defer();

            $http({
                method: 'GET',
                url: './api/importData/getImportTemplate',
                params: { importType: importType }
            }).success(function(data, status, headers) {
                headers = headers();

                var contentType = headers['content-type'];
                var fileName = headers['content-disposition'].split('filename=')[1].replace(/['"]+/g, '');

                var linkElement = document.createElement('a');
                try {
                	//El fichero llega codificado en Base64, hay que decodificarlo
                	var byteCharacters = atob(data);                	
                	var byteNumbers = new Array(byteCharacters.length);
                	for (var i = 0; i < byteCharacters.length; i++) {
                	    byteNumbers[i] = byteCharacters.charCodeAt(i);
                	}                	
                	var byteArray = new Uint8Array(byteNumbers);
                	
                	var blob = new Blob([byteArray], {type: contentType});
                    var url = window.URL.createObjectURL(blob);
                    linkElement.setAttribute('href', url);
                    linkElement.setAttribute('download', fileName);

                    deferrend.resolve(linkElement);
                } catch(ex) {
                    deferrend.reject(ex);
                }
            }).error(function(error) {
                deferrend.reject(error);
            })

            return deferrend.promise;
        }
        
        factory.validateImportFile = function (importType, fileToValidate) {
            var deferrend = $q.defer();            
            $http({
                method: 'POST',
                url: './api/importData/validateImportFile',
                params: { importType: importType },
                data: JSOG.encode(fileToValidate)
            }).success(function(data, status, headers) {
            	if (data) {
            		headers = headers();

                    var contentType = headers['content-type'];
                    var fileName = headers['content-disposition'].split('filename=')[1].replace(/['"]+/g, '');

                    var linkElement = document.createElement('a');
                    try {
                    	//El fichero llega codificado en Base64, hay que decodificarlo
                    	var byteCharacters = atob(data);                	
                    	var byteNumbers = new Array(byteCharacters.length);
                    	for (var i = 0; i < byteCharacters.length; i++) {
                    	    byteNumbers[i] = byteCharacters.charCodeAt(i);
                    	}                	
                    	var byteArray = new Uint8Array(byteNumbers);
                    	
                    	var blob = new Blob([byteArray], {type: contentType});
                        var url = window.URL.createObjectURL(blob);
                        linkElement.setAttribute('href', url);
                        linkElement.setAttribute('download', fileName);

                        deferrend.resolve(linkElement);
	            	} catch(ex) {
	                    deferrend.reject(ex);
	                }
            	} else {
            		deferrend.resolve(data);
            	}
            }).error(function(error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        }
        
        factory.executeImportFile = function (importType, fileToImport) {
        	var deferrend = $q.defer();            
            $http({
                method: 'POST',
                url: './api/importData/executeImportFile',
                params: { importType: importType },
                data: JSOG.encode(fileToImport)
            }).success(function(data, status, headers) {
            	if (data) {
            		headers = headers();

                    var contentType = headers['content-type'];
                    var fileName = headers['content-disposition'].split('filename=')[1].replace(/['"]+/g, '');

                    var linkElement = document.createElement('a');
                    try {
                    	//El fichero llega codificado en Base64, hay que decodificarlo
                    	var byteCharacters = atob(data);                	
                    	var byteNumbers = new Array(byteCharacters.length);
                    	for (var i = 0; i < byteCharacters.length; i++) {
                    	    byteNumbers[i] = byteCharacters.charCodeAt(i);
                    	}                	
                    	var byteArray = new Uint8Array(byteNumbers);
                    	
                    	var blob = new Blob([byteArray], {type: contentType});
                        var url = window.URL.createObjectURL(blob);
                        linkElement.setAttribute('href', url);
                        linkElement.setAttribute('download', fileName);

                        deferrend.resolve(linkElement);
	            	} catch(ex) {
	                    deferrend.reject(ex);
	                }
            	} else {
            		deferrend.resolve(data);
            	}
            }).error(function(error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        }
        
        factory.importDataAfterCheckPassword = function() {
        	if(this.annexaFormly.form.$valid) {
                var modal = this;
                var model = modal.annexaFormly.model.modal_body;

                $http({
                    url: './api/users/check_password/' + $rootScope.LoggedUser.id,
                    method: 'GET',
                    params:{
                        password:model.password
                    }
                }).then(function(data) {
                    if(data.data) {
                    	modal.close();
                    	$rootScope.$broadcast('executeImportDataPasswordOK', {importType: model.importType, file: model.file, modalAlerts: model.modalAlerts, modalClose: model.modalClose});
                    } else {
                    	modal.alerts.push({ msg: 'global.errors.checkPasswordError' });
                    }
                }).catch(function(error) {
                	modal.alerts.push({ msg: 'global.errors.checkPasswordServiceError' });
                })
            }
        }
        
        factory.checkPasswordModal = function(importType, file, modalAlerts, modalClose) {
        	var modal = angular.copy(globalModals.checkPassword);
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {importType: importType, file: file, modalAlerts: modalAlerts, modalClose: modalClose};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = { readOnly: false };
            modal.alerts = [];

            AnnexaFormlyFactory.showModal('modalCheckPassword', modal, factory.importDataAfterCheckPassword, false, undefined, 'global.literals.accept');
    	}        
        
        factory.importDataAdmin = function (scope, importType, table) {  
        	
        	if (scope.$$listenerCount['executeImportDataPasswordOK'] == undefined || scope.$$listenerCount['executeImportDataPasswordOK'] === 0) {
        		//Solo crea el evento si no existe
	        	scope.$on('executeImportDataPasswordOK', function(event, args) {
	    			if (args && args.importType == importType && args.file) {
	    				//La contraseña es correcta, realizo la importación del fichero                		
	            		$rootScope.loading(true);
	                	var reader = new FileReader();
	                	reader.onload = function (e) {
	                		var fileToImport = { file: CommonService.base64ArrayBuffer(e.target.result), fileName: args.file.name, fileType: args.file.type };
	                		factory.executeImportFile(importType, fileToImport).then(function (data) {
	                			if (data) {
	                				//no pasa la validación ya que retorna el fichero
	                				if (args.modalAlerts) {
	                					args.modalAlerts.length = 0;
	                					args.modalAlerts.push({msg: 'global.literals.imports.resultValidationKO'});
	                				}
	                				var clickEvent = new MouseEvent("click", {
	                                    "view": window,
	                                    "bubbles": true,
	                                    "cancelable": false
	                                });
	
	                                $rootScope.loading(false);
	                                data.dispatchEvent(clickEvent);	                                    
	                			} else {
	                				//Se han importado los datos correctamente
	                				if (args.modalAlerts) {
	                					args.modalAlerts.length = 0;
	                				}
	                				if (args.modalClose) {
	                					args.modalClose();
	                				}
	                				
	                				//Recarga la tabla
	                				if (table) {
	                					table.reloadInternalData(true,true);
	                				} else if (importType == "ARCHIVE_CLASSIFICATION") {
	                					scope.reloadTableArchiveClassifications();
	                				} else if (importType == "FAMILY_PROCEDURE") {
	                					scope.reloadFamilyProcedures();
	                				}
	                				
	                				$rootScope.loading(false);
	                				DialogsFactory.notify($filter('translate')('global.literals.imports.resultImportOK'));
	                			}
	            			}).catch(function (error) {
	            				$rootScope.loading(false);
	            				if (args.modalAlerts) {
	            					args.modalAlerts.length = 0;
	            					args.modalAlerts.push({msg: 'global.literals.imports.importGeneralError'});
	            				}
	            			});
	                	};
	                	reader.readAsArrayBuffer(args.file);   
	                	$rootScope.loading(false);
	    			}        			
	            });
        	}
        	
        	var importData = function(file){
        		if (file) {
	        		var self = this;
	        		factory.checkPasswordModal(importType, file, self.alerts, self.close);
        		}
            };
            
            var validateData = function(file){
            	$rootScope.loading(true);
            	var self = this;
            	var reader = new FileReader();
            	reader.onload = function (e) {
            		var fileToValidate = { file: CommonService.base64ArrayBuffer(e.target.result), fileName: file.name, fileType: file.type };
            		factory.validateImportFile(importType, fileToValidate).then(function (data) {
            			if (data) {
            				//no pasa la validación ya que retorna el fichero
            				self.alerts = [{msg: 'global.literals.imports.resultValidationKO'}];
            				var clickEvent = new MouseEvent("click", {
                                "view": window,
                                "bubbles": true,
                                "cancelable": false
                            });

                            $rootScope.loading(false);
                            data.dispatchEvent(clickEvent);
            			} else {
            				//ha pasado la validación
            				self.alerts.length = 0;
            				$rootScope.loading(false);
            				DialogsFactory.notify($filter('translate')('global.literals.imports.resultValidationOK'));
            			}
        			}).catch(function (error) {
        				$rootScope.loading(false);
        				self.alerts = [{msg: 'global.literals.imports.validateGeneralError'}];
        			});
            	};
            	reader.readAsArrayBuffer(file);   
            	$rootScope.loading(false);
            };	
            
            var getImportTemplate = function(){
            	 $rootScope.loading(true);
            	 var self = this;
            	 
            	 factory.getImportTemplate(importType)
                     .then(function (linkElement) {
                         var clickEvent = new MouseEvent("click", {
                             "view": window,
                             "bubbles": true,
                             "cancelable": false
                         });

                         $rootScope.loading(false);
                         linkElement.dispatchEvent(clickEvent);
                     }).catch(function (error) {
                     $rootScope.loading(false);
                     self.alerts = [{msg: 'global.literals.imports.downloadTemplateKO'}];
                 });
            };
        	
            var modal = CommonAdminModals.importDataGeneral;
            modal.alerts = [];
            var form = new AnnexaFormly();
            modal.validateData = validateData;
            modal.importData = importData;
            modal.getImportTemplate = getImportTemplate;
            modal.form = form;
            modal.id = 'modalImportData'+new Date().getTime();
            modal.noFooter = false;
            modal.annexaFormly = form;
            modal.submitModal = modal.annexaFormly.onSubmit;
            AnnexaModalFactory.showModal(modal.id, modal);
        }
        
		factory.findDocumentationProvideByArchiveClassification = function(id){
			var deferrend = $q.defer();            
            $http({
                method: 'GET',
                url: './api/archiveClassifications/documentationProvice/byArchiveClassification/'+id,
            }).success(function(data, status, headers) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(error) {
                deferrend.reject(error);
            });
            return deferrend.promise;
		}
		factory.getEntities = function(){
			return $http({
                method: 'GET',
                url: './api/tram/spel_function/evaluator/entities/SPEL'
            }).success(function (data, status) {
				factory.projectEntities = [];
				var ents = JSOG.decode(data);
				if(ents && ents.length > 0){
					_.forEach(ents, function(ent){
						factory.projectEntities.push({id:ent, name:ent});
					})
				}
            }).error(function (msg, code) {
				factory.projectEntities = [];
            });
		}
		factory.openUnguidedProcedureList = function(){
        	var promises = [];
            promises.push(RestService.findAll('Profile'));
            promises.push(RestService.findAll('RoleInterested'));
            promises.push(RestService.findAll('CouncillorProposalTypeDelegationType'));
            return $q.all(promises).then(function(data) {
            	factory.profiles = new SortedArray(JSOG.decode(data[0].data), Language.getActiveColumn()).sort();
                var rolesInt = new SortedArray(JSOG.decode(data[1].data), Language.getActiveColumn()).sort();
                factory.rolesInterested = ((rolesInt)?$linq(rolesInt).where("x => x.canUseDossier").toArray():[]);
             	factory.councillorProposalTypeDelegationType = JSOG.decode(data[2].data);
            }).catch(function(error) {
                factory.profiles = [];
                factory.rolesInterested = [];
                factory.councillorProposalTypeDelegationType = [];
            });
        }        
		factory.getActionCatalogs = function() {
			var promises = [];
			factory.actionCatalogs = [];
			factory.allQueries = [];
            promises.push(RestService.findAll('ActionCatalog'));
            promises.push(RestService.findAll('Query', 'annexaAutomatism'));
            promises.push($http({method: 'GET',url: './api/tram/spel_function/evaluator/entities/AUTOMATISM'}));
            return $q.all(promises)
            .then(function(data) {
            	factory.actionCatalogs = JSOG.decode(data[0].data);
            	factory.allQueries = JSOG.decode(data[1].data);
            	factory.projectEntities = [];
				var ents = JSOG.decode(JSOG.decode(data[2].data));
				if(ents && ents.length > 0){
					_.forEach(ents, function(ent){
						factory.projectEntities.push({id:ent, name:ent});
					})
				}
            }).catch(function(error) {
                factory.actionCatalogs = [];
                factory.allQueries = [];
                factory.projectEntities = [];
            });
        }		
		factory.updateQueryParamsFields = function(queryParametersField, model){
			var updateQueryParamsFieldsFunction = function(){
				var deferrendFunc = $q.defer();
				if(queryParametersField && queryParametersField.fieldGroup){
					if(factory.allQueries && factory.allQueries.length > 0){
						if(model && model.query){
							var query = $linq(factory.allQueries).firstOrDefault(undefined,"x => x.id == "+model.query);
							model.queryParametersOrig = [];
							if(query){
								if(query.parameters && query.parameters.length > 0){
									queryParametersField.fieldGroup.push({
										key: 'labelParams',
										type: 'annexaLabelRow',
										className: 'col-sm-12',
										templateOptions: {
											label: '',
											value: ''
										},
										data: {
											informed: true,
											row: true,
											colClass: ' col-sm-12',
											labelClass: 'label-strong text',
											breakLine: true
										},
										controller: function ($scope, $filter) {
											$scope.to.label = $filter("translate")("global.querys.automatisms.queryParameters");
										}
									});
									var parameters = $linq(query.parameters).orderBy("x => x.viewOrder").toArray();
									_.forEach(parameters, function(param){
										model.queryParametersOrig.push({id:param.queryParameter.id , alias:param.queryParameter.alias});
										if(param && param.queryParameter){
											queryParametersField.fieldGroup.push(QueryFactory.createQueryParameterField(param, false, false, 'col-sm-4'));
										}
									});
								}
								deferrendFunc.resolve("query exist");
							}else{
								deferrendFunc.reject("No query");
							}
						}else{
							deferrendFunc.resolve("No query selected");
						}
					}else{
						deferrendFunc.reject("No queries");
					}
				}else{
					deferrendFunc.reject("No field");
				}
				return deferrendFunc.promise;	
			}
			var deferrend = $q.defer();            
			setTimeout(function() {
				updateQueryParamsFieldsFunction().then(function(data) {
					deferrend.resolve(data);
				}).catch(function(error) {
					deferrend.reject(error);
				});
			}, 100);
			return deferrend.promise;
		}
		factory.updateQueryColumnsFields = function(columnsTypesField, model){
			var deferrend = $q.defer();   
			if(columnsTypesField && columnsTypesField.fieldGroup){
				if(model && model.query){
					model.queryColumnsOrig = [];
					$http({method: 'GET',url: './api/query/getColumnsQuery/'+model.query})
					.success(function(data, status, headers) {
						var columns = JSOG.decode(data);
						if(columns && columns.length > 0){
							var entitiesOptions = angular.copy(factory.projectEntities);
							model.queryColumnsOrig = angular.copy(columns);
							columnsTypesField.fieldGroup.push({
								key: 'labelColumnTypes',
								type: 'annexaLabelRow',
								className: 'col-sm-12',
								templateOptions: {
									label: '',
									value: ''
								},
								data: {
									informed: true,
									row: true,
									colClass: ' col-sm-12',
									labelClass: 'label-strong text',
									breakLine: true
								},
								controller: function ($scope, $filter) {
									$scope.to.label = $filter("translate")("global.querys.automatisms.columnsTypes");
								}
							});
							_.forEach(columns, function(column){
								if(column){
									columnsTypesField.fieldGroup.push({
		                                key: column,
		                                type: 'annexaSelectRow',
		                                className: 'col-sm-4',
		                                templateOptions: {
		                                    optionsAttr: 'bs-options',
		                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
		                                    label: column,
		                                    valueProp: 'id',
		                                    labelProp: 'name',
		                                    placeholder: '',
		                                    options: [],
		                                    required: true
		                                },
		                                controller:['$scope', 'AdminFactory', 'Language', function($scope, AdminFactory, Language) {
											$scope.to.options = entitiesOptions;
		                                }],
										data: {
		                                    row: true,
		                                    informed: true,
		                                    colClass: ' col-xs-12',
		                                    labelClass: 'label-strong',
		                                    clear: function($event,model,key, $select) {
		                                        $event.stopPropagation();
		                                        model[key] = undefined;
		                                        if($select) {
		                                            $select.selected = undefined;
		                                            $select.search = undefined;
		                                        }
		                                    }
		                                }
	                                });
								}
							});
							deferrend.resolve("columns exist");
						}else{
							deferrend.reject("No columns");
						}
		            }).error(function(error) {
		                deferrend.reject(error);
		            });
				}else{
					deferrend.resolve("No query selected");
				}
			}else{
				deferrend.reject("No field");
			}
            return deferrend.promise;
		}
		factory.updateAutomatismActionFields = function(automatismActionparametersField, model){
			var updateAutomatismActionFieldsFunction = function(){
				var language = Language.getActiveColumn();
				if(!language){
					language = "language1";
				}
				var deferrendFunc = $q.defer();
				if(automatismActionparametersField && automatismActionparametersField.fieldGroup){
					if(factory.actionCatalogs && factory.actionCatalogs.length > 0){
						if(model && model.actionCatalog){
							var acat = $linq(factory.actionCatalogs).firstOrDefault(undefined,"x => x.id == "+model.actionCatalog);
							model.actionCatalogParametersOrig = [];
							if(acat){
								if(acat.parameters){
									var aCParams = $linq(acat.parameters).orderBy("x => x.viewOrder").toArray() 
            						_.forEach(aCParams, function(param){
										model.actionCatalogParametersOrig.push({id:param.id, acronym:param.acronym});
										var fieldACP = undefined;
										var fieldACPExtra = undefined;
										if((acat.acronym === 'sendMail' && (param.acronym === 'subject' || param.acronym === 'body')) || (acat.acronym === 'createUnguidedDossierTransaction' && param.acronym === 'comment') || (acat.acronym === 'createGuidedDossierTransaction'  && param.acronym === 'comment')){
											fieldACP = {
				                                key: param.acronym,
				                                type: 'annexaTextAreaRow',
				                                className: 'col-sm-4',
				                                templateOptions: {
				                                    required: ((param.required)?true:false),
				                                    type: 'text',
				                                    rows: 5,
				                                    maxLength: 5000,
				                                    label: param[language]
				                                },
				                                data: {
				                                    row: true,
				                                    colClass: ' col-xs-12',
				                                    labelClass: 'label-strong'
				                                }
			                            	};	
											fieldACPExtra = {
												key: param.acronym+"ParamColumnTypeExtraField",
			    								type: 'annexaLabelRow',
			    								className: 'col-sm-12',
			    								templateOptions: {
			    									label: '',
			    									value: ''
			    								},
			    								data: {
			    									informed: true,
			    									row: true,
			    									colClass: ' col-sm-12',
			    									labelClass: 'label-strong small',
			    									breakLine: true
			    								}
			    							};	
										}else{
											fieldACP = {
				                                key: param.acronym,
				                                type: 'annexaInputRow',
				                                className: 'col-sm-4',
				                                templateOptions: {
				                                    required: ((param.required)?true:false),
				                                    type: 'text',
				                                    label: param[language]
				                                },
				                                data: {
				                                    row: true,
				                                    colClass: ' col-xs-12',
				                                    labelClass: 'label-strong'
				                                }
			                            	};
										}
										var fieldACPPCT = {
			                                key: param.acronym+"ParamColumnType",
			                                type: 'annexaSelectRow',
			                                className: 'col-sm-4',
			                                templateOptions: {
			                                    optionsAttr: 'bs-options',
			                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
			                                    label: param[language]+" "+$filter("translate")("global.literals.type"),
			                                    valueProp: 'id',
			                                    labelProp: 'name',
			                                    placeholder: '',
			                                    options: [],
			                                    required: ((param.required)?true:false)
			                                },
			                                controller:['$scope', 'Language', function($scope, Language) {
			                                	var options = [];
			                                	if(param.parameterType === 'String'){
			                                		options.push({id:"Column",name:$filter("translate")("global.querys.automatisms.column")});
			                                		options.push({id:"HybridColumn",name:$filter("translate")("global.querys.automatisms.hybridColumn")});
			                                	}else{
			                                		options.push({id:"Column",name:$filter("translate")("global.querys.automatisms.column")});
			                                	}
			                                	options.push({id:param.parameterType, name:param.parameterType});
												$scope.to.options = options;
			                                }],
											data: {
			                                    row: true,
			                                    informed: true,
			                                    colClass: ' col-xs-12',
			                                    labelClass: 'label-strong',
    		                                    clear: function($event,model,key, $select) {
    		                                        $event.stopPropagation();
    		                                        model[key] = undefined;
    		                                        if($select) {
    		                                            $select.selected = undefined;
    		                                            $select.search = undefined;
    		                                        }
    		                                    }
			                                },
			                                hideExpression: function ($viewValue, $modelValue, scope) {
			    								if(param && !param.required && param.acronym){
			    									var cronField = $linq(scope.fields).firstOrDefault(undefined, "x => x.key == '"+param.acronym+"ParamColumnType'");
			    			                        if(cronField && cronField.templateOptions){
			    			                        	cronField.templateOptions.required = ((scope.model && scope.model[param.acronym])?true:false);
			    			                        }
			    								}
			    								return false;
			                                }
		                                }
										var fieldACPPFCT = {
		    								key: param.acronym+"ParamFinalColumnType",
		    								type: 'annexaLabelRow',
		    								className: 'col-sm-4 m-b-md',
		    								templateOptions: {
		    									label: '',
		    									value: ''
		    								},
		    								data: {
		    									informed: true,
		    									row: true,
		    									colClass: ' col-sm-12',
		    									labelClass: 'label-strong small',
		    									breakLine: true
		    								},
		    								controller: function ($scope, $filter, Language) {
		    									$scope.to.label = param[Language.getActiveColumn()]+" "+$filter("translate")("global.querys.automatisms.finaltype");
		    									$scope.to.value = param.finalParameterType;
		    								}
		    							}
										if(acat && acat.acronym === 'startDossier' && (param.acronym === 'registerEntry' || param.acronym === 'dossier' || param.acronym === 'relatedDossierRelation' || param.acronym === 'task')){
                        					fieldACP.hideExpression = function ($viewValue, $modelValue, scope) {
                        						if(scope.$parent && scope.$parent.model && scope.$parent.model.actionCatalog){
                        							var adminFactory = angular.element(document.body).injector().get('AdminFactory');
                                            		if(adminFactory && adminFactory.actionCatalogs && adminFactory.actionCatalogs.length > 0){
                                            			var sd = $linq(adminFactory.actionCatalogs).firstOrDefault(undefined, "x => x.id == "+scope.$parent.model.actionCatalog);
                                            			if(sd && sd.acronym === 'startDossier' && scope.$parent.model.origType && 
                                            					((scope.$parent.model.origType === 'REGISTER' && param.acronym === 'registerEntry') ||
                                            					 (scope.$parent.model.origType === 'DOSSIER' && param.acronym === 'dossier') ||	
                                            					 (scope.$parent.model.origType === 'DOSSIER' && param.acronym === 'relatedDossierRelation') ||
                                            					 (scope.$parent.model.origType === 'TASK' && param.acronym === 'task'))){
                                            				return false;
                                            			}else{
                                            				scope.model[param.acronym] = undefined;
                                                			return true;
                                            			}
                                            		}else{
                                            			scope.model[param.acronym] = undefined;
                                            			return true;
                                            		}
                        						}else{
                                                	scope.model[param.acronym] = undefined;
                	                                return true;
                								}
			                                }
                        					fieldACPPCT.hideExpression = function ($viewValue, $modelValue, scope) {
			    								if(param && !param.required && param.acronym){
			    									var cronField = $linq(scope.fields).firstOrDefault(undefined, "x => x.key == '"+param.acronym+"ParamColumnType'");
			    			                        if(cronField && cronField.templateOptions){
			    			                        	cronField.templateOptions.required = ((scope.model && scope.model[param.acronym])?true:false);
			    			                        }
			    								}
			    								if(scope.$parent && scope.$parent.model && scope.$parent.model.actionCatalog){
                        							var adminFactory = angular.element(document.body).injector().get('AdminFactory');
                                            		if(adminFactory && adminFactory.actionCatalogs && adminFactory.actionCatalogs.length > 0){
                                            			var sd = $linq(adminFactory.actionCatalogs).firstOrDefault(undefined, "x => x.id == "+scope.$parent.model.actionCatalog);
                                            			if(sd && sd.acronym === 'startDossier' && scope.$parent.model.origType && 
                                            					((scope.$parent.model.origType === 'REGISTER' && param.acronym === 'registerEntry') ||
                                            					 (scope.$parent.model.origType === 'DOSSIER' && param.acronym === 'dossier') ||	
                                            					 (scope.$parent.model.origType === 'DOSSIER' && param.acronym === 'relatedDossierRelation') ||
                                            					 (scope.$parent.model.origType === 'TASK' && param.acronym === 'task'))){
                                            				return false;
                                            			}else{
                                                			return true;
                                            			}
                                            		}else{
                                            			return true;
                                            		}
                        						}else{
                	                                return true;
                								}
			                                }
                        					fieldACPPFCT.hideExpression = function ($viewValue, $modelValue, scope) {
                        						if(scope.$parent && scope.$parent.model && scope.$parent.model.actionCatalog){
                        							var adminFactory = angular.element(document.body).injector().get('AdminFactory');
                                            		if(adminFactory && adminFactory.actionCatalogs && adminFactory.actionCatalogs.length > 0){
                                            			var sd = $linq(adminFactory.actionCatalogs).firstOrDefault(undefined, "x => x.id == "+scope.$parent.model.actionCatalog);
                                            			if(sd && sd.acronym === 'startDossier' && scope.$parent.model.origType && 
                                            					((scope.$parent.model.origType === 'REGISTER' && param.acronym === 'registerEntry') ||
	                                                				 (scope.$parent.model.origType === 'DOSSIER' && param.acronym === 'dossier') ||	
	                                                				 (scope.$parent.model.origType === 'DOSSIER' && param.acronym === 'relatedDossierRelation') ||
	                                                				 (scope.$parent.model.origType === 'TASK' && param.acronym === 'task'))){
                                            				return false;
                                            			}else{
                                                			return true;
                                            			}
                                            		}else{
                                            			return true;
                                            		}
                        						}else{
                	                                return true;
                								}
			                                }
                        				}
										automatismActionparametersField.fieldGroup.push(fieldACP);
										automatismActionparametersField.fieldGroup.push(fieldACPPCT);
										automatismActionparametersField.fieldGroup.push(fieldACPPFCT);
										if(fieldACPExtra){
											automatismActionparametersField.fieldGroup.push(fieldACPExtra);
										}
									});
								}
								deferrendFunc.resolve("Action exist");
							}else{
								deferrendFunc.reject("No action catalog");
							}
						}else{
							deferrendFunc.resolve("No action catalog selected");
						}
					}else{
						deferrendFunc.reject("No action catalogs");
					}
				}else{
					deferrendFunc.reject("No field");
				}
				return deferrendFunc.promise;	
			}
		
			var deferrend = $q.defer();
			setTimeout(function() {
				updateAutomatismActionFieldsFunction().then(function(data) {
					deferrend.resolve(data);
				}).catch(function(error) {
					deferrend.reject(error);
				});
			}, 100);
			return deferrend.promise;
		}
		
		factory.updateAutomatismCustomFields = function(dossierCustomFieldsField, dossierTransactionCustomFieldsField, model) {
			var deferrend = $q.defer();
			if(model && model.objectId){
				factory.getAutomatismCustomFields(model.objectId).then(function (data){
					var dcf = ((data && data.dossierCustomFields)?data.dossierCustomFields:[]);
					var dtcf = ((data && data.dossierTransactionCustomFields)?data.dossierTransactionCustomFields:[]);
					if(model.actionCatalog){
						var acat = $linq(factory.actionCatalogs).firstOrDefault(undefined,"x => x.id == "+model.actionCatalog);
						if(acat && acat.acronym === 'startDossier' && model.objectId && dcf && dcf.length > 0 && dossierCustomFieldsField){
							model.dossierCustomFieldsOrig = [];
							model.dossierCustomFields = {};
	    					if(dcf){
	    						dossierCustomFieldsField.fieldGroup.push({
	    							key: 'labeldossierCustomFieldsField',
	    							type: 'annexaLabelRow',
	    							className: 'col-sm-12',
	    							templateOptions: {
	    								label: '',
	    								value: ''
	    							},
	    							data: {
	    								informed: true,
	    								row: true,
	    								colClass: ' col-sm-12',
	    								labelClass: 'label-strong text',
	    								breakLine: true
	    							},
	    							controller: function ($scope, $filter) {
	    								$scope.to.label = $filter("translate")("global.literals.customFieldsDossier");
	    							}
	    						});
	    						var dcfOrder = $linq(dcf).orderBy("x => x.viewOrder").toArray()
				                _.forEach(dcfOrder, function(field) {
				        			model.dossierCustomFieldsOrig.push(field);
									dossierCustomFieldsField.fieldGroup.push({
											key: field.customField.templateTag,
											type: 'annexaInputRow',
											className: 'col-sm-6',
											templateOptions: {
												required: ((field.required)?true:false),
												type: 'text',
												label: field.customField[Language.getActiveColumn()]
											},
											data: {
												row: true,
												colClass: ' col-xs-12',
												labelClass: 'label-strong'
											}
									});
				                	dossierCustomFieldsField.fieldGroup.push({
											key: field.customField.templateTag+"ParamColumnType",
											type: 'annexaSelectRow',
											className: 'col-sm-6 m-b-xs',
											templateOptions: {
												optionsAttr: 'bs-options',
												ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
												label: field.customField[Language.getActiveColumn()]+" "+$filter("translate")("global.literals.type"),
												valueProp: 'id',
												labelProp: 'name',
												placeholder: '',
												options: [],
												required: ((field.required)?true:false)
											},
											controller:['$scope', 'Language', function($scope, Language) {
												var options = [];
												if(field && field.customField && field.customField.backendType === 'STRING'){
													options.push({id:"Column",name:$filter("translate")("global.querys.automatisms.column")});
													options.push({id:"HybridColumn",name:$filter("translate")("global.querys.automatisms.hybridColumn")});
												}else{
													options.push({id:"Column",name:$filter("translate")("global.querys.automatisms.column")});
												}
												options.push({id:field.customField.backendType, name:field.customField.backendType});
												$scope.to.options = options;
											}],
											data: {
												row: true,
												informed: true,
												colClass: ' col-xs-12',
												labelClass: 'label-strong',
												clear: function($event,model,key, $select) {
													$event.stopPropagation();
													model[key] = undefined;
													if($select) {
														$select.selected = undefined;
														$select.search = undefined;
													}
												}
											},
											hideExpression: function ($viewValue, $modelValue, scope) {
												if(field && !field.required && field.customField && field.customField.templateTag){
													var cronField = $linq(scope.fields).firstOrDefault(undefined, "x => x.key == '"+field.customField.templateTag+"ParamColumnType'");
													if(cronField && cronField.templateOptions){
														cronField.templateOptions.required = ((scope.model && scope.model[field.customField.templateTag])?true:false);
													}
												}
												return false;
											}
									});
				                });
	    					}
						}
	    				if(acat && acat.acronym === 'startDossier' && model.objectId && dtcf && dtcf.length > 0 && dossierTransactionCustomFieldsField){
	    					model.dossierTransactionCustomFieldsOrig = [];
							model.dossierTransactionCustomFields = {};
	    					if(dtcf){
	    						dossierTransactionCustomFieldsField.fieldGroup.push({
	    							key: 'labeldossierTransactionCustomFieldsField',
	    							type: 'annexaLabelRow',
	    							className: 'col-sm-12',
	    							templateOptions: {
	    								label: '',
	    								value: ''
	    							},
	    							data: {
	    								informed: true,
	    								row: true,
	    								colClass: ' col-sm-12',
	    								labelClass: 'label-strong text',
	    								breakLine: true
	    							},
	    							controller: function ($scope, $filter) {
	    								$scope.to.label = $filter("translate")("global.literals.customFieldsDossierTransaction");
	    							}
	    						});
	    						var dtcfOrder = $linq(dtcf).orderBy("x => x.viewOrder").toArray()
				                _.forEach(dtcfOrder, function(field) {
	    							model.dossierTransactionCustomFieldsOrig.push(field);
	    							dossierTransactionCustomFieldsField.fieldGroup.push({
										key: field.customField.templateTag,
										type: 'annexaInputRow',
										className: 'col-sm-6',
										templateOptions: {
											required: ((field.required)?true:false),
											type: 'text',
											label: field.customField[Language.getActiveColumn()]
										},
										data: {
											row: true,
											colClass: ' col-xs-12',
											labelClass: 'label-strong'
										}
									});
	    							dossierTransactionCustomFieldsField.fieldGroup.push({
											key: field.customField.templateTag+"ParamColumnType",
											type: 'annexaSelectRow',
											className: 'col-sm-6 m-b-xs',
											templateOptions: {
												optionsAttr: 'bs-options',
												ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
												label: field.customField[Language.getActiveColumn()]+" "+$filter("translate")("global.literals.type"),
												valueProp: 'id',
												labelProp: 'name',
												placeholder: '',
												options: [],
												required: ((field.required)?true:false)
											},
											controller:['$scope', 'Language', function($scope, Language) {
												var options = [];
												if(field && field.customField && field.customField.backendType === 'STRING'){
													options.push({id:"Column",name:$filter("translate")("global.querys.automatisms.column")});
													options.push({id:"HybridColumn",name:$filter("translate")("global.querys.automatisms.hybridColumn")});
												}else{
													options.push({id:"Column",name:$filter("translate")("global.querys.automatisms.column")});
												}
												options.push({id:field.customField.backendType, name:field.customField.backendType});
												$scope.to.options = options;
											}],
											data: {
												row: true,
												informed: true,
												colClass: ' col-xs-12',
												labelClass: 'label-strong',
												clear: function($event,model,key, $select) {
													$event.stopPropagation();
													model[key] = undefined;
													if($select) {
														$select.selected = undefined;
														$select.search = undefined;
													}
												}
											},
											hideExpression: function ($viewValue, $modelValue, scope) {
												if(field && !field.required && field.customField && field.customField.templateTag){
													var cronField = $linq(scope.fields).firstOrDefault(undefined, "x => x.key == '"+field.customField.templateTag+"ParamColumnType'");
													if(cronField && cronField.templateOptions){
														cronField.templateOptions.required = ((scope.model && scope.model[field.customField.templateTag])?true:false);
													}
												}
												return false;
											}
									});
				                });
	    					}
	    				}
					}
    				var returnData = {haveDossierCustomFields: false, haveDossierTransactionCustomFields:false};
        			if(dossierTransactionCustomFieldsField && dossierTransactionCustomFieldsField.fieldGroup && dossierTransactionCustomFieldsField.fieldGroup.length > 0){
        				returnData.haveDossierCustomFields = true;
        			}
        			if(dossierTransactionCustomFieldsField && dossierTransactionCustomFieldsField.fieldGroup && dossierTransactionCustomFieldsField.fieldGroup.length > 0){
        				returnData.haveDossierTransactionCustomFields = true;
        			}
        			deferrend.resolve(returnData);
                }).catch(function (error) {
                	deferrend.reject("Error get custom fields");
                });
			}else{
				deferrend.reject("No objectId");
			}
			return deferrend.promise;
		}
		
		factory.getAutomatismCustomFields = function(automatismId) {
			var deferred = $q.defer();
            $http({
                url: './api/automatism/customFields/'+automatismId,
                method: 'GET'
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }		
		return factory;
    }]);
