angular
    .module('annexaApp')
        .controller('DelegationAdminController',['$scope', '$rootScope', 'AnnexaFormlyFactory', 'AnnexaModalFactory', 'CommonAdminModals', 'HeaderService', '$state', 'TableFilter', 'DTColumnBuilder', '$filter', 'RestService', 'Language', 'HelperService', 'ErrorFactory','AdminFactory', 'DialogsFactory', 'CommonService', '$http', 'apiSign', 'LoginService',
            function($scope, $rootScope, AnnexaFormlyFactory, AnnexaModalFactory, CommonAdminModals, HeaderService, $state, TableFilter, DTColumnBuilder, $filter, RestService, Language, HelperService, ErrorFactory, AdminFactory, DialogsFactory, CommonService, $http, apiSign, LoginService) {
                $scope.showLoadingdiv = false;
                $scope.languageColumn = Language.getActiveColumn();
                $scope.tfilter = new TableFilter();
                $scope.tfilter.addElement('delegatorUser', 'text', 1, 'global.delegate.delegatorUser').setFilterCallAux(true);
                $scope.tfilter.addElement('delegatedUser', 'text', 2, 'global.delegate.delegatedUser').setFilterCallAux(true);
                $scope.tfilter.addElement('startDelegationDate', 'dateRange', 3, 'global.delegate.startDate').setFilterCallAux(true);
                $scope.tfilter.addElement('endDelegationDate', 'dateRange', 4, 'global.delegate.endDate').setFilterCallAux(true);
                $scope.tfilter.addElement('positions', 'select', 5, 'global.delegate.delegatedPositions', 3, HelperService.addAllSelectAngularTranslate(AdminFactory.positions), true, $scope.languageColumn, true).setFilterCallAux(true);
                $scope.dtDefDelegations = new TableData('common', 'DelegationUser').setSort([[1, 'asc']]).setSortName('id');

                var startDateColumn = new DateColumn($filter, 'global.delegate.startDate');
                var endDateColumn = new DateColumn($filter, 'global.delegate.endDate');
                var delegationsActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                    new ActionButton('global.literals.see', 'openDelegation([data])', 'fa-eye'),
                    [new ActionButton('global.literals.remove', 'deleteDelegation([data])')]);

                $scope.renderDelegationUserPositions = function(delegationUserPositions){
        			var literal = "";
        			var first = true;
        			angular.forEach(delegationUserPositions, function (value, key) {
        				if(first) {
        					literal += value.userPosition.position[$scope.languageColumn];
        					first = false;
        				} else {
        					literal += ", " + value.userPosition.position[$scope.languageColumn];
        				}
                    });
        			return literal;
        		}

                $scope.tableDelegations = {
                    dtColumns: [
                        DTColumnBuilder.newColumn('id').withTitle("id").withOption('width', '6%'),
                        DTColumnBuilder.newColumn('delegatorUser.completeName').withTitle($filter('translate')('global.delegate.delegatorUser')),
                        DTColumnBuilder.newColumn('delegatedUser.completeName').withTitle($filter('translate')('global.delegate.delegatedUser')),
                        DTColumnBuilder.newColumn('startDelegationDate').withTitle(startDateColumn.getTitle()).renderWith(startDateColumn.getRender),
                        DTColumnBuilder.newColumn('endDelegationDate').withTitle(endDateColumn.getTitle()).renderWith(endDateColumn.getRender),
                        DTColumnBuilder.newColumn('delegationUserPositions').withTitle($filter('translate')('global.delegate.delegatedPositions')).renderWith($scope.renderDelegationUserPositions),
                        DTColumnBuilder.newColumn('id').withOption('className', 'text-center').withOption('width', '90px').withTitle(delegationsActionsColumn.getTitle()).renderWith(delegationsActionsColumn.getRender).notSortable()
                    ],
                    filterCall: $scope.tfilter.getFilterCall(),
                    filterCallAux: {loggedUser: $rootScope.LoggedUser.id, langColumn: $scope.languageColumn},
                    reloadInternalData: function (resetPaging) {
                        this.filterCall = $scope.tfilter.getFilterCall();
                        this.filterCallAux = $scope.tfilter.getFilterCallAux();
                        this.filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                        this.filterCallAux.langColumn = $scope.languageColumn;
                        this.table.reloadData(function callbackInstance(json) {}, resetPaging);
                    }
                };

                HeaderService.onChangeState($scope, function (message) {
                    if(message.state.name == 'annexa.admin.delegation') {
                        $rootScope.subHeadButtons = [
                            new HeadButtonSearch("#tableFilter"),
                            new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#admin-delegations','fa fa-plus','global.delegate.titleNew',undefined,'openNewDelegation')
                        ];
                    }
                });

                HeaderService.changeState($state.current);

                $scope.doSearchFilter = function() {
                    $scope.tableDelegations.reloadInternalData(true);
                };

                $scope.deleteDelegation = function(id) {
                    if(id) {
                    	DialogsFactory.confirm('global.delegate.deleteDelegation', 'global.delegate.deleteDelegationConfirm')
                        .then(function (data) {
                    		var sendData = {idDelegation : id};    
                    		RestService.insertOrUpdate(apiSign.deleteDelegationUsers, sendData).then(function (data) {
                                $scope.doSearchFilter();
                                var deleg = JSOG.decode(data.data);
                                if(deleg && deleg.delegatorUser.id == $rootScope.LoggedUser.id){
                                	$rootScope.$broadcast('modifyDelegations',{deleg: deleg, deleted:true});
                                }
                            }).catch(function (error) {
                            	DialogsFactory.error($filter('translate')('global.delegate.deleteError'), $filter('translate')('DIALOGS_ERROR_MSG'));
                            });
         	           }).catch(function (data) {});
                    }
                };

                $scope.openNewDelegation = function () {
                    var modal = angular.copy(CommonAdminModals.delegationNew);
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = {};
                    modal.annexaFormly.options = {};
                    modal.annexaFormly.options.formState = { readOnly: false};
                    modal.extra = {reloadTable: $scope.doSearchFilter};
                    AnnexaFormlyFactory.showModal('modalDelegation', modal, $scope.saveNewDelegation, false, undefined);
                };
                
                $scope.saveNewDelegation = function(){
                    if (this.annexaFormly.form.$valid) {
                    	var error = false;
                        if(this.annexaFormly.model.startDelegationDate > this.annexaFormly.model.endDelegationDate){
                        	error = true;
                        }
                        var self = this;
                        var delegationUserPositions = [];
                        var delegatorUserPositions = this.annexaFormly.model.delegatorUser.user.userPositions;
                    	angular.forEach(this.annexaFormly.model.positions, function(value, key){
                    		var userPosition = $linq(delegatorUserPositions).firstOrDefault(undefined, "x => x.position && x.position.id == "+value);
                    		delegationUserPositions.push({ userPosition: {id: userPosition.id} });
                    	});

                    	if(!error){
                        	var data = {
                                delegatorUser: {id: this.annexaFormly.model.delegatorUser.id},
                                delegatedUser: {id: this.annexaFormly.model.delegatedUser.id},
                                startDelegationDate: new Date(CommonService.formatDate(this.annexaFormly.model.startDelegationDate, false, false)),
                                endDelegationDate: new Date(CommonService.formatDate(this.annexaFormly.model.endDelegationDate, false, false)),
                                delegationUserPositions: delegationUserPositions,
                                createdDate: new Date()
                            }
                            var sendData = {delegationUser: angular.toJson(data)};
                            RestService.insertOrUpdate(apiSign.insertDelegationUsers, sendData).then(function (dataGetDocument) {
                            	if(self.annexaFormly.model.delegatorUser.id == $rootScope.LoggedUser.id){
                            		$rootScope.$broadcast('modifyDelegations',{deleg: JSOG.decode(dataGetDocument.data), deleted:false});
                            	}
                                self.extra.reloadTable();
                                self.close();
                            }).catch(function (error) {
                            	self.alerts.push({ msg: $filter('translate')('global.delegate.errorCreate') });
                            });
                        }else{
                        	self.alerts.push({ msg: $filter('translate')('global.delegate.errorDates') });
                        }
                    }
                };
                
                $scope.openDelegation = function (id) {
                    if(id) {
                    	RestService.findOne('DelegationUser', id)
                        .then(function(data) {
                            var modal = angular.copy(CommonAdminModals.delegationEdit);
                            modal.data = JSOG.decode(data.data);
                            modal.alerts = [];
                            modal.extra = {reloadTable: $scope.doSearchFilter};
                            modal.languageColumn = $scope.languageColumn;
                            modal.userPositions = $linq(modal.data.delegationUserPositions).where("x => x.userPosition && x.userPosition.position").select("x => x.userPosition.position").toArray();
                            modal.positions = $linq(modal.data.delegatorUser.userPositions).select("x => x.position").toArray();
                            modal.printPositions = $scope.printPositions;
                            modal.updateDelegation = $scope.updateDelegation;
                            AnnexaModalFactory.showModal('modalDelegationEdit', modal);
                        });
                    }
                };
                
                $scope.printPositions = function (model) {
                    var positions = '';

                    _.forEach(model, function (val) {
                        if(positions != '') {
                            positions += ', ';
                        }

                        positions += val[$scope.languageColumn];
                    });

                    return positions;
                }

                $scope.updateDelegation = function(val, prop) {
                	var delegation = angular.copy(this.data);
                	var error = false;
                    if(prop == 'startDelegationDate' &&  val > delegation.endDelegationDate){
                    	error = true;
                    }else if(prop == 'endDelegationDate' &&  delegation.startDelegationDate > val){
                    	error = true;
                    } 
                    
                	var self = this;
                    if(!error){
                    	self.alerts = [];
                        if (prop == 'positions') {
                            var delegationUserPositions = [];
                            var delegatorUserPositions = delegation.delegatorUser.userPositions;
                        	angular.forEach(val, function(value){
                        		var userPosition = $linq(delegatorUserPositions).firstOrDefault(undefined, "x => x.position && x.position.id == " + value.id);
                        		var delegationUserPosition = $linq(delegation.delegationUserPositions).firstOrDefault(undefined, "x => x.userPosition.id == " + userPosition.id);
                        		if (delegationUserPosition) {
                        			delegationUserPositions.push({ id: delegationUserPosition.id, createdDate: delegationUserPosition.createdDate, delegationUser: {id: delegationUserPosition.delegationUser.id}, userPosition: {id: delegationUserPosition.userPosition.id} });
                        		} else {
                        			delegationUserPositions.push({ userPosition: {id: userPosition.id} });	
                        		}
                        	});
                        	
                        	delegation.delegationUserPositions = delegationUserPositions;
                        } else {
                        	delegation[prop] = val;
                        }
                        
            	    	var sendData = {delegationUser: angular.toJson(delegation)};
                        RestService.insertOrUpdate(apiSign.insertDelegationUsers, sendData).then(function (dataGetDocument) {
                        	if(delegation.delegatorUser.id == $rootScope.LoggedUser.id){
                        		$rootScope.$broadcast('modifyDelegations',{deleg: JSOG.decode(dataGetDocument.data), deleted:false});
                        	}
                        	self.extra.reloadTable();
                            self.close();
                        }).catch(function (error) {
                        	self.alerts.push({ msg: $filter('translate')('global.delegate.errorCreate') });
                        });
                    }else{
                    	self.alerts.push({ msg: $filter('translate')('global.delegate.errorDates') });
                    }
                };
            }]);