angular
    .module('annexaApp')
    .controller('NotificationTypesController',['$scope', '$rootScope', 'CommonAdminModals', 'AnnexaFormlyFactory', 'RestService', 'AnnexaModalFactory', 'Language', '$filter', 'GlobalDataFactory', 'CacheFactory', 'DialogsFactory', function($scope, $rootScope, CommonAdminModals, AnnexaFormlyFactory, RestService, AnnexaModalFactory, Language, $filter, GlobalDataFactory, CacheFactory,DialogsFactory) {

        $scope.newElement = function() {
            var modal = angular.copy(CommonAdminModals.notificationTypeNew);
            modal.annexaFormly.model = { row1: {}, row2: { allowManualStatusChange: true }, row3: {}, row4: {}, row9: {addRemittanceSicer: false} };
            modal.annexaFormly.options = {};

            AnnexaFormlyFactory.showModal('modalNewNotificationType', modal, $scope.saveNotificationType, false);
        }

        $scope.statuses = [];

        $rootScope.$on('AnnexaNotificationStatusAdded', function(event, args) {
            $scope.statuses = [];

            _.forEach(args.statuses, function (value) {
                $scope.statuses.push(value);
            });
        });

        $rootScope.$on('AnnexaNotificationStatusUpdated', function(event, args) {
            if(args.status) {
                var update = {
                    createdDate: args.status.createdDate,
                    deleted: args.status.deleted,
                    id: args.status.id,
                    revision: args.status.revision,
                    type: args.status.type,
                    language1: args.status.language1 ? args.status.language1 : 'NotTranslated',
                    language2: args.status.language2 ? args.status.language2 : 'NotTranslated',
                    language3: args.status.language3 ? args.status.language3 : 'NotTranslated',
                    allowEvidence: args.status.allowEvidence,
                    globalStatus: args.status.globalStatus,
					orderView: args.status.orderView,
					finalServiceStatus: args.status.finalServiceStatus
                };

                if(update.id) {
                    RestService.update('./api/reg/notification_status/' + update.id, update)
                        .then(function () {
                            //Empty
                        	if(GlobalDataFactory.notificationTypes){
                            	GlobalDataFactory.notificationTypes.length = 0;
                            }else{ 
                            	GlobalDataFactory.notificationTypes = [];
                            }
                            if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=NotificationType')){
                            	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=NotificationType')
                            }
                            GlobalDataFactory.loadGlobalData();
                        }).catch(function (error) {
							if(error && error.data && error.data.message && error.data.message === 'Final service status duplicated'){
			                   	DialogsFactory.error($filter('translate')('global.errors.duplicatedServiceStatus'))
							}else{
								DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
							}
                    });
                } else {
                    RestService.insert('./api/reg/notification_status', update)
                        .then(function(data) {
							args.status.id = data.id;
                        	if(GlobalDataFactory.notificationTypes){
                            	GlobalDataFactory.notificationTypes.length = 0;
                            }else{
                            	GlobalDataFactory.notificationTypes = [];
                            }
                            if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=NotificationType')){
                            	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=NotificationType')
                            }
                            GlobalDataFactory.loadGlobalData();
                        }).catch(function(error) {
                        	if(error && error.data && error.data.message && error.data.message === 'Final service status duplicated'){
			                   DialogsFactory.error($filter('translate')('global.errors.duplicatedServiceStatus'))
							}else{
								DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'))
							}
                    });
                }
            }
        });

        $scope.saveNotificationType = function (notificationType) {
            var modal = this;

            var model = {
                language1: notificationType.annexaFormly.model.row1.language1,
                language2: notificationType.annexaFormly.model.row1.language2 ? notificationType.annexaFormly.model.row1.language2 : 'NotTranslated',
                language3: notificationType.annexaFormly.model.row1.language3 ? notificationType.annexaFormly.model.row1.language3 : 'NotTranslated',
                addressType: notificationType.annexaFormly.model.row2.addressType ? notificationType.annexaFormly.model.row2.addressType : null,
                allowManualStatusChange: notificationType.annexaFormly.model.row2.allowManualStatusChange,
                registerEntryChannel: {id:notificationType.annexaFormly.model.row3.registerEntryChannel},
                addressTypeAlert: notificationType.annexaFormly.model.row4.addressTypeAlert ? notificationType.annexaFormly.model.row4.addressTypeAlert : null,
                limitSizeDocumentsNotifications: notificationType.annexaFormly.model.row5.limitSizeDocumentsNotifications ? notificationType.annexaFormly.model.row5.limitSizeDocumentsNotifications : null,
        		limitSizeExtractNotifications: notificationType.annexaFormly.model.row6.limitSizeExtractNotifications ? notificationType.annexaFormly.model.row6.limitSizeExtractNotifications : null,
                limitNumberDocumentsNotifications: notificationType.annexaFormly.model.row8.limitNumberDocumentsNotifications ? notificationType.annexaFormly.model.row8.limitNumberDocumentsNotifications : null,
                statuses: $scope.statuses,
                identificationDestinataryType: notificationType.annexaFormly.model.row8.identificationDestinataryType ? notificationType.annexaFormly.model.row8.identificationDestinataryType : null,
                identificationDestinataryPattern: notificationType.annexaFormly.model.row8.identificationDestinataryPattern ? notificationType.annexaFormly.model.row8.identificationDestinataryPattern : null,
                addRemittanceSicer: notificationType.annexaFormly.model.row9.addRemittanceSicer
            }

            RestService.insert('./api/reg/notification_type', model)
                .then(function () {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable'); 
                    $scope.statuses = [];
                    if(GlobalDataFactory.notificationTypes){
                    	GlobalDataFactory.notificationTypes.length = 0;
                    }else{
                    	GlobalDataFactory.notificationTypes = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=NotificationType')){
                    	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=NotificationType')
                    }
                    GlobalDataFactory.loadGlobalData();
                    modal.close();
                }).catch(function (error) {
                    if(error && error.data && error.data.message && error.data.message === 'Final service status duplicated'){
	                    modal.alerts.push({ msg: $filter('translate')('global.errors.duplicatedServiceStatus') })
					}else{
						modal.alerts.push({ msg: $filter('translate')('global.errors.unknownNew') })
					}
            })
        }

        $scope.viewElement = function (id) {
            RestService.findOne('NotificationType', id)
                .then(function(data) {
                    var decodeddata = JSOG.decode(data.data);
					if(decodeddata && decodeddata.statuses){
						_.forEach(decodeddata.statuses, function(obj){
							if(obj.type && obj.type.id){
								obj.type = {id:obj.type.id};
							}
						});
					}
                    var modal = CommonAdminModals.notificationTypeEdit;
                    modal.alerts = [];
                    modal.data = decodeddata;
                    modal.languageColumn = Language.getActiveColumn();
                    modal.nameColumn = 'name';
                    modal.addressTypes = [
                        { id: 'TELEMATIC_EMAIL', name: 'global.literals.TELEMATIC_EMAIL' },
                        { id: 'POSTAL', name: 'global.literals.POSTAL' },
                        { id: 'TELEMATIC_MOBILEPHONE', name: 'global.literals.TELEMATIC_MOBILEPHONE' },
                        { id: 'POSTAL_CODE', name: 'global.literals.POSTAL_CODE' },
                        { id: 'OTHER', name: 'global.literals.others' }
                    ]
                    modal.identificationDestinataryTypes = [
                    	{ id: 'START', name: 'global.enums.identificationDestinataryPatternType.START' },
                        { id: 'NOT_START', name: 'global.enums.identificationDestinataryPatternType.NOT_START' },
                        { id: 'FINISH', name: 'global.enums.identificationDestinataryPatternType.FINISH' },
                        { id: 'NOT_FINISH', name: 'global.enums.identificationDestinataryPatternType.NOT_FINISH' },
                        { id: 'CONTAINS', name: 'global.enums.identificationDestinataryPatternType.CONTAINS' },
                        { id: 'NOT_CONTAINS', name: 'global.enums.identificationDestinataryPatternType.NOT_CONTAINS' }
                    ]
                    modal.registerEntryChannels =  GlobalDataFactory.registerEntryChannelsOutput;
                    modal.printAddressType = function (value) {
                    	if(value) {
                    		return $filter('translate')('global.literals.' + ((value && value.id)?value.id:value));
                    	}
                        return '';
                    }
                    modal.printIdentificationDestinataryType = function (value) {
                        if(value && value != 'null'){
                        	return $filter('translate')('global.enums.identificationDestinataryPatternType.' + ((value && value.id)?value.id:value));
                        } else {
                        	return '';
                        }
                    }
                    modal.printRegisterEntryChannel = function(value){
                    	return value[Language.getActiveColumn()];
                    }
                    modal.amscs = [
                        { id: true, name: $filter('translate')('global.literals.yes') },
                        { id: false, name: $filter('translate')('global.literals.no') }
                    ];
                    modal.printAmcs = function(modelValue) {
                        if(modelValue) {
                            return $filter('translate')('global.literals.yes');
                        } else {
                            return $filter('translate')('global.literals.no');
                        }
                    };

                    modal.updateNotificationType = $scope.updateNotificationType;

                    AnnexaModalFactory.showModal('modalNotificationTypeEdit', modal);
                });
        }

        $scope.updateNotificationType = function(val, prop) {
			var self = this;
			var statusesAux = this.data.statuses;
            var modelAux = angular.copy(this.data);
			var model = angular.copy(this.data);
			if(prop == 'addressType') {
                model.addressType = val.id;
            } else if (prop == 'allowManualStatusChange' || prop == 'addRemittanceSicer') {
                model[prop] = val.id;
            }else if (prop == 'registerEntryChannel'){
            	model.registerEntryChannel = {id:val.id};
            }else if (prop == 'limitSizeDocumentsNotifications'){
            	model.limitSizeDocumentsNotifications = val;
            }else if (prop == 'limitSizeExtractNotifications'){
            	model.limitSizeExtractNotifications = val;
            }else if (prop == 'limitNumberDocumentsNotifications'){
            	model.limitNumberDocumentsNotifications = val;
            }else if (prop == 'addressTypeAlert'){
            	if(val && val.id) {
            		model.addressTypeAlert = val.id;
            	} else {
            		model.addressTypeAlert = undefined;
            	}
            }else if (prop == 'identificationDestinataryType'){
            	model.identificationDestinataryType = val.id;
            }else if (prop == 'identificationDestinataryPattern'){
            	model.identificationDestinataryPattern = val;
            }
            else {
                model = val;
            }

			if(model.allowManualStatusChange && model.allowManualStatusChange.id){
				model.allowManualStatusChange = model.allowManualStatusChange.id;
			}
			
            RestService.update('./api/reg/notification_type/' + model.id, model)
                .then(function (data) {
                   	self.data = data;
					self.data.statuses = statusesAux;
                    $rootScope.$broadcast('annexaAdminBaseReloadTable');
                    if(GlobalDataFactory.notificationTypes){
                    	GlobalDataFactory.notificationTypes.length = 0;
                    }else{
                    	GlobalDataFactory.notificationTypes = [];
                    }
                    if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=NotificationType')){
                    	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=NotificationType')
                    }
                    GlobalDataFactory.loadGlobalData();
                }).catch(function (error) {
					self.data = modelAux;
					self.data.statuses = statusesAux;
					if(error && error.data && error.data.message && error.data.message === 'Final service status duplicated'){
	                    self.alerts.push({ msg: $filter('translate')('global.errors.duplicatedServiceStatus') })
					}else{
						self.alerts.push({ msg: $filter('translate')('global.errors.unknownUpdate') })
					}
			});
        }
    }])